import typeToReducer from 'type-to-reducer';
import {
  CREATE_TEAM_MEMBER,
  GET_TEAM,
  UPDATE_TEAM_MEMBER,
  GET_TEAM_MEMBER_BY_ID,
} from './constants';

export const initialState = {
  loading: false,
  teamMembers: [],
  teamMember: null,
  pages: 0,
  count: 0,
  errors: [],
};

export default typeToReducer(
  {
    [GET_TEAM]: {
      START: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          teamMembers: action.payload.data && action.payload.data.team_members,
          pages: action.payload.data && action.payload.data.pages,
          count: action.payload.data && action.payload.data.count,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [CREATE_TEAM_MEMBER]: {
      START: (state) => {
        return {
          ...state,
          articleLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          articleLoading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          articleLoading: false,
        };
      },
    },
    [GET_TEAM_MEMBER_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          teamMember: action.payload.data.team_member,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [UPDATE_TEAM_MEMBER]: {
      START: (state) => {
        return {
          ...state,
          articleLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          articleLoading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          articleLoading: false,
        };
      },
    },
  },
  initialState
);
