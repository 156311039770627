import React from 'react'
import { string } from 'prop-types'

const PaymentInfo = ({ date, time }) => {
  return (
    <div className='form--column'>
      <h2 className='form--title2'>Payment Info</h2>
      <div className='form--row'>
        <div className='form--label'>Date payment</div>
        <div className='form--input'>{date}</div>
      </div>
      <div className='form--row'>
        <div className='form--label'>Time</div>
        <div className='form--input'>{time}</div>
      </div>
    </div>
  )
}

PaymentInfo.propTypes = {
  date: string.isRequired,
  time: string.isRequired,
}

export default PaymentInfo
