import React from 'react'
import { bool, string } from 'prop-types'

const AppointmentInfo = ({ isVideo, isChild, date, start_time, end_time, price }) => {
  return (
    <div className='form--column'>
      <h2 className='form--title2'>Appointment info</h2>
      <div className='form--row'>
        <p className='form--label'>Appointment Type</p>
        <div className='form--input '>{isVideo ? 'Video consultation' : 'Allergy test'}</div>
      </div>
      <div className='form--row'>
        <p className='form--label'>Appointment Age Type</p>
        <div className='form--input '>{isChild ? 'Child appointment' : 'Adult appointment'}</div>
      </div>
      <div className='form--row'>
        <p className='form--label'>Date Appointment</p>
        <div className='form--input  datepicker-here'>{date}</div>
      </div>
      <div className='form--row'>
        <p className='form--label'>Date Appointment</p>
        <div className='form--input  timepicker-here'>
          {start_time} - {end_time}
        </div>
      </div>
      <div className='form--row'>
        <p className='form--label'>Price</p>
        <div className='form--input '>€ {price}</div>
      </div>
    </div>
  )
}

AppointmentInfo.propTypes = {
  isVideo: bool.isRequired,
  isChild: bool.isRequired,
  date: string.isRequired,
  start_time: string.isRequired,
  end_time: string.isRequired,
  price: string.isRequired,
}

export default AppointmentInfo
