import api from '../../utils/api';

export const getMonthSlotsApi = (data) =>
  api.get('/calendar_slots', { params: data });
export const getSessionsApi = (data) =>
  api.get('/patient_sessions', { params: data });
export const bookPreliminarilyApi = (id) => api.put(`/patient_sessions/${id}`);
export const createPatientApi = (data) => api.post('/patients', data);
export const initiatePaymentApi = (data) => api.post('/payments', data);
export const getPatientApi = (id) => api.get(`/patients/${id}`);
