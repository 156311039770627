import * as Yup from 'yup';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 20;
const TEL_MIN_LENGTH = 6;
const TEL_MAX_LENGTH = 12;
const MESSAGE_MIN_LENGTH = 10;
const MESSAGE_MAX_LENGTH = 300;

const REQUIRED_MESSAGE = 'This field is required , please fill in.';
const TO_SHORT_MESSAGE = 'Text is too short.';
const TO_LONG_MESSAGE = 'Text is too long.';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const hasReferralLetterSchema = Yup.object().shape({
  signature: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('match-fullname', 'Signature must match your full name', function (
      value
    ) {
      return value === this.parent.full_name;
    }),
  full_name: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  email: Yup.string().email('Mail is invalid.').required(REQUIRED_MESSAGE),
  phone: Yup.string()
    .min(TEL_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(TEL_MAX_LENGTH, TO_LONG_MESSAGE)
    .matches(phoneRegExp, 'Phone number is invalid.')
    .required(REQUIRED_MESSAGE),
});

export const withoutReferralLetterSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  address: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  email: Yup.string().email('Mail is invalid.').required(REQUIRED_MESSAGE),
  phone: Yup.string()
    .min(TEL_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(TEL_MAX_LENGTH, TO_LONG_MESSAGE)
    .matches(phoneRegExp, 'Phone number is invalid.')
    .required(REQUIRED_MESSAGE),
  gp_details: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  what_allergy_do_you_think_you_might_have_and_why: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  known_allergies_description: Yup.string(),
  signature: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('match-fullname', 'Signature must match your full name', function (
      value
    ) {
      return value === this.parent.full_name;
    }),
});
