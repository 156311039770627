import React from 'react'
import DatePickerLib from 'react-datepicker'

import '../../assets/styles/react-datepicker.css'
import moment from 'moment'
import { func, bool, instanceOf } from 'prop-types'

export default function DatePicker({ date = '', setDate, touch, isAdmin }) {
  return (
    <DatePickerLib
      selected={date}
      onChange={setDate}
      dropdownMode='select'
      dateFormat='dd.MM.yyyy'
      maxDate={new Date()}
      onCalendarClose={touch}
      customInput={
        <div>
          <input
            className={`form__input ${isAdmin ? 'admin-form--input' : ''} form__date-picker-input`}
            type='text'
            name='birthday'
            placeholder='Patient DOB'
            value={date && moment(date).format('DD.MM.YYYY')}
            disabled
          />
          <i className={`icons i24x24 i-calendar ${isAdmin ? 'admin-calendar-icon' : ''}`} />
        </div>
      }
      dayClassName={() => 'ui-state-default'}
      showYearDropdown
      showMonthDropdown
    />
  )
}

DatePicker.propTypes = {
  date: instanceOf(Date),
  setDate: func.isRequired,
  touch: func.isRequired,
  isAdmin: bool,
}
