import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { string, elementType, bool } from 'prop-types'
import { routeToAllergyType } from '../../../../constants/routes'

const TypeListItem = ({ Svg, ActiveSvg, title, id, isActive }) => {
  const [hover, setHover] = useState(false)

  return (
    <div className='allergyTypes__nav--item' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Link className={`btns btn-b ${isActive ? 'active' : ''}`} to={routeToAllergyType(id)}>
        {isActive || hover ? <ActiveSvg className='icon i64x64' /> : <Svg className='icon i64x64' />}
        {title}
      </Link>
    </div>
  )
}

TypeListItem.propTypes = {
  Svg: elementType.isRequired,
  ActiveSvg: elementType.isRequired,
  title: string.isRequired,
  id: string.isRequired,
  isActive: bool.isRequired,
}

export default TypeListItem
