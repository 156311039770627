import React from 'react';
import moment from 'moment';
import FilterIcon from '../admin/components/Icons/Filter';

export default [
  {
    name: <div className='table-header-item'>Full Name</div>,
    selector: 'full_name',
  },
  {
    name: <div className='table-header-item'>User Type</div>,
    selector: 'user_type',
  },
];
