import { createAction } from 'redux-actions';

import { GET_FAQ_LANDING } from './constants';

import { getFaqLandingApi } from './api';

export const getFaqLandingAction = createAction(
  GET_FAQ_LANDING,
  async (data) => await getFaqLandingApi(data)
);
