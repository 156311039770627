import React, { useEffect, useState } from 'react';
import {
  objectOf,
  arrayOf,
  bool,
  number,
  func,
  string,
  object,
} from 'prop-types';
import { connect } from 'react-redux';
import TableList from '../../components/TableList';
import StaticPage from '../../../components/StaticPage';
import { deleteTeamMemberAction, getTeamAction } from './actions';
import tableHeaderData from '../../../utils/teamTableHeader';
import { DEFAULT_PER_PAGE_TEAM } from './constants';
import Loader from '../../../components/Loader';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../../../containers/Notification/actions';

const AdminTeam = (props) => {
  const {
    loading,
    teamMembers,
    getTeam,
    deleteTeamMember,
    location,
    count,
    successNotification,
    errorNotification,
  } = props;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_TEAM);
  const [search, setSearch] = useState('');
  const [shouldResetPagination, setShouldResetPagination] = useState(false);

  // Request for search article by title
  // eslint-disable-next-line no-shadow
  const searchTeamMemberHandler = ({ search }) => getTeam({ title: search });

  const deleteTeamMemberHandler = async (id) => {
    try {
      const data = { page, per_page: perPage };

      await deleteTeamMember(id);
      await getTeam(data);
      successNotification('You have successfully deleted the article');
    } catch (e) {
      console.error(e);
      errorNotification();
    }
  };

  useEffect(
    // eslint-disable-next-line no-shadow
    () => {
      const data = { page, per_page: perPage };
      getTeam(data);
    },
    [page, perPage, getTeam]
  );

  const onChangePageHandler = async (_page) => {
    setPage(_page);
    setShouldResetPagination(true);
    const data = { page: _page, per_page: perPage };
    await getTeam(data);
  };

  return (
    <StaticPage isAdmin noFooter pageClassName='appointments__page'>
      <div className='content__main appointments__page page'>
        <div className='site__width'>
          {loading ? (
            <Loader styles={{ margin: '20vh' }} />
          ) : (
            <div className='table--list appointments--tables'>
              <TableList
                tableData={teamMembers}
                tableHeaderData={tableHeaderData}
                deleteHandler={deleteTeamMemberHandler}
                onChangePageHandler={onChangePageHandler}
                loading={loading}
                count={count}
                page={page}
                search={search}
                setSearch={setSearch}
                perPage={perPage}
                changePerPageHandler={setPerPage}
                shouldResetPagination={shouldResetPagination}
                isTeam
              />
            </div>
          )}
        </div>
      </div>
    </StaticPage>
  );
};

const mapStateToProps = ({ team }) => ({
  loading: team.loading,
  teamMembers: team.teamMembers,
  count: team.count,
  errors: team.errors,
});

const mapDispatchToProps = (dispatch) => ({
  getTeam: async (data) => await dispatch(getTeamAction(data)),
  deleteTeamMember: async (id) => await dispatch(deleteTeamMemberAction(id)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

AdminTeam.propTypes = {
  loading: bool,
  teamMembers: arrayOf(object),
  count: number,
  location: objectOf(string),
  getTeam: func.isRequired,
  deleteTeamMember: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeam);
