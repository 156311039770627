import React, { useEffect, useState } from 'react';
import {
  objectOf,
  arrayOf,
  bool,
  number,
  func,
  string,
  object,
} from 'prop-types';
import { connect } from 'react-redux';
import TableList from '../../components/TableList';
import StaticPage from '../../../components/StaticPage';
import {
  getAppointmentsAction,
  deleteAppointmentAction,
  getExportedTableAction,
} from './actions';
import tableHeaderData from '../../../utils/appointementTableHeader';
import { ROUTE_TO_VIDEO_ADMIN } from '../../../constants/routes';
import { DEFAULT_PER_PAGE_APPOINTMENTS } from './constants';
import Loader from '../../../components/Loader';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../../../containers/Notification/actions';

const AllergyTestPage = (props) => {
  const {
    loading,
    appointments,
    getAppointments,
    deleteAppointment,
    location,
    count,
    successNotification,
    errorNotification,
    getExportedTable,
  } = props;
  const isVideo = location.pathname === ROUTE_TO_VIDEO_ADMIN;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_APPOINTMENTS);
  const [search, setSearch] = useState('');
  const [shouldResetPagination, setShouldResetPagination] = useState(false);

  // Request for search patient by name, phone or email
  const searchAppointmentHandler = ({ search }) => {
    const data = { is_video_call: isVideo, search_transaction: search };
    return getAppointments(data);
  };
  const deleteAppointmentHandler = async (id) => {
    try {
      const data = { is_video_call: isVideo, page, per_page: perPage };

      await deleteAppointment(id);
      await getAppointments(data);
      successNotification('You have successfully deleted the appointment');
    } catch (e) {
      console.error(e);
      errorNotification();
    }
  };

  useEffect(
    // eslint-disable-next-line no-shadow
    () => {
      const data = { is_video_call: isVideo, page, per_page: perPage };
      getAppointments(data);
    },
    [page, perPage, isVideo, getAppointments]
  );

  const onChangePageHandler = async (_page) => {
    setPage(_page);
    setShouldResetPagination(true);
    const data = { is_video_call: isVideo, page: _page, per_page: perPage };
    await getAppointments(data);
  };

  return (
    <StaticPage isAdmin noFooter pageClassName='appointments__page'>
      <div className='content__main appointments__page page'>
        <div className='site__width'>
          {loading ? (
            <Loader />
          ) : (
            <div className='table--list appointments--tables'>
              <TableList
                tableData={appointments}
                tableHeaderData={tableHeaderData}
                deleteHandler={deleteAppointmentHandler}
                searchHandler={searchAppointmentHandler}
                onChangePageHandler={onChangePageHandler}
                loading={loading}
                count={count}
                page={page}
                search={search}
                setSearch={setSearch}
                perPage={perPage}
                changePerPageHandler={setPerPage}
                shouldResetPagination={shouldResetPagination}
                onExport={getExportedTable}
                isVideo={isVideo}
              />
            </div>
          )}
        </div>
      </div>
    </StaticPage>
  );
};

const mapStateToProps = ({ appointment }) => ({
  loading: appointment.appointmentsLoading,
  appointments: appointment.appointments,
  count: appointment.appointmentsCount,
  errors: appointment.errors,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: async (data) => await dispatch(getAppointmentsAction(data)),
  getExportedTable: async (data) =>
    await dispatch(getExportedTableAction(data)),
  deleteAppointment: async (id) => await dispatch(deleteAppointmentAction(id)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

AllergyTestPage.propTypes = {
  loading: bool,
  appointments: arrayOf(object),
  count: number,
  location: objectOf(string),
  getAppointments: func.isRequired,
  deleteAppointment: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  getExportedTable: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllergyTestPage);
