import React from 'react'
import { func } from 'prop-types'
import Icon from '../../../Icons/Delete'

const DeleteRowButton = ({ onDelete }) => {
  return (
    <button type='button' onClick={onDelete} className='btns delete_btn' title='Delete'>
      <Icon />
    </button>
  )
}

DeleteRowButton.propTypes = {
  onDelete: func.isRequired,
}

export default DeleteRowButton
