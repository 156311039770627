import React from 'react'

const OralAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Oral allergy Syndrome</h2>
      <p>
        Oral allergy is caused as a result of a cross reaction of allergens found in raw fruit, vegetables, pollen and
        or some tree nuts. The pollen and similar proteins in the food triggers the immune system to direct an allergic
        response. Usually those affected by oral allergy syndrome can eat some fruit or vegetables when cooked, as the
        heating process denatures the proteins and therefore the immune system doesn’t identify foods as harmful.
      </p>
    </div>
  )
}

export default OralAllergyInfo
