import React from 'react';
import { Link } from 'react-router-dom';
import { any, objectOf } from 'prop-types';
import { routeToEmployee } from '../../constants/routes';
import config from '../../config';

const PersonCard = ({ person }) => {
  const { full_name, user_type, logo_url, id } = person;

  return (
    <div className='team__list--item'>
      <figure className='team__list--image'>
        <Link className='btns btn-b' to={routeToEmployee(id)} title={full_name}>
          <img
            className='image'
            src={`${config.REACT_APP_UPLOADS_URL}/${logo_url}`}
            alt={full_name}
            style={{ objectFit: 'cover', width: '315px', height: '335px' }}
          />
        </Link>
      </figure>
      <h2 className='team__list--title'>
        <Link to={routeToEmployee(id)} title={full_name}>
          {full_name}
        </Link>
      </h2>
      <div className='team__list--subtitle'>{user_type}</div>
      <div className='team__list--link'>
        <Link className='btns' to={routeToEmployee(id)} title='Learn more'>
          Learn more <i className='icons i22x10 i-arrow_right' />
        </Link>
      </div>
    </div>
  );
};

PersonCard.propTypes = { person: objectOf(any).isRequired };

export default PersonCard;
