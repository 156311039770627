import React from 'react'
import { bool, func, string } from 'prop-types'

const AppointmentHeader = ({ firstName, lastName, status, loading, showModalHandler, bookedByClient }) => {
  const clientName = `${firstName} ${lastName}`
  const succeeded = status === 'succeeded'

  const formattedStatus = succeeded ? 'Paid' : status

  return (
    <header className='appointment--edit_header clearfix'>
      <div className='appointment--edit_header-text'>
        <h1 className='appointment--edit_header-title form--title'>{clientName}</h1>
      </div>
      <div className='appointment--edit_header-btns'>
        {bookedByClient ? (
          <button
            type='button'
            className='btns'
            onClick={succeeded ? () => showModalHandler(true) : () => console.log('You can`t refund')}
            title={succeeded ? 'Make payment refund' : null}>
            {loading ? 'Loading...' : formattedStatus}
          </button>
        ) : (
          <div className='btns' style={{ cursor: 'default' }}>
            Booked by admin
          </div>
        )}
      </div>
    </header>
  )
}

AppointmentHeader.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  status: string.isRequired,
  loading: bool.isRequired,
  bookedByClient: bool.isRequired,
  showModalHandler: func.isRequired,
}

export default AppointmentHeader
