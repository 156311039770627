import React from 'react'
import { arrayOf, object } from 'prop-types'
import PersonCard from '../PersonCard'

const PersonList = ({ items }) => {
  return (
    <div className='team__list'>
      {items.map((person) => (
        <PersonCard person={person} key={person.id} />
      ))}
    </div>
  )
}

PersonList.propTypes = { items: arrayOf(object) }

export default PersonList
