import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import appReducer from './containers/App/reducer';
import blogReducer from './containers/Blog/reducer';
import bookingReducer from './containers/Booking/reducer';
import authReducer from './admin/containers/Auth/reducer';
import availabilityReducer from './admin/containers/Availability/reducer';
import appointmentReducer from './admin/containers/AllergyTest/reducer';
import adminBlogReducer from './admin/containers/Blog/reducer';
import teamReducer from './admin/containers/Team/reducer';
import faqReducer from './admin/containers/FAQ/reducer';
import faqLandingReducer from './components/Pages/Faqs/reducer';
import teamLandingReducer from './components/Pages/OurTeam/reducer';
import appointmentAdminReducer from './admin/containers/Appointments/reducer';

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  blog: blogReducer,
  booking: bookingReducer,
  appointment: appointmentReducer,
  availability: availabilityReducer,
  adminBlog: adminBlogReducer,
  team: teamReducer,
  faq: faqReducer,
  faqLanding: faqLandingReducer,
  teamLanding: teamLandingReducer,
  appointmentAdmin: appointmentAdminReducer,
  notifications,
});
