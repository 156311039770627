import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPatientByIdAction } from '../actions';
import StaticPage from '../../../../components/StaticPage';
import moment from 'moment';

const PatientAppointment = (props) => {
  const { loading, getPatient, match, patient } = props;

  const { id } = match.params;

  useEffect(() => {
    getPatient(id);
  }, [id, getPatient]);

  return (
    <StaticPage isAdmin noFooter pageClassName='appointments__page'>
      <div className='content__main appointments__page page'>
        {loading ? null : (
          <div
            className='site__width'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: 20,
              width: 600,
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              <a
                class='btns btn-b btn-red_b btn-appnt'
                title='Back to appointments'
                href='/admin'>
                Back to appointments
              </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <label style={{ fontSize: '20px', fontWeight: 600 }}>
                  Patient Full Name:
                </label>
                <div className='form__value'>{patient.full_name}</div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <label style={{ fontSize: '20px', fontWeight: 600 }}>
                  Date of Birth:
                </label>
                <div className='form__value'>
                  {moment(patient.dob).format('DD/MM/YYYY')}
                </div>
              </div>

              {!patient.is_referral && (
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    Patient Address:
                  </label>
                  <div className='form__value'>{patient.address}</div>
                </div>
              )}

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <label style={{ fontSize: '20px', fontWeight: 600 }}>
                  Contact Email:
                </label>
                <div className='form__value'>{patient.email}</div>
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <label style={{ fontSize: '20px', fontWeight: 600 }}>
                  Contact Phone Number:
                </label>
                <div className='form__value'>{patient.phone}</div>
              </div>
            </div>

            {patient.is_referral && (
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <label style={{ fontSize: '20px', fontWeight: 600 }}>
                  Referral letter:
                </label>
                <a
                  download
                  href={
                    process.env.REACT_APP_UPLOADS_URL + patient.attachment_url
                  }
                  class='btns btn-b btn-red_b btn-appnt'>
                  Download referral letter
                </a>
              </div>
            )}

            {!patient.is_referral && (
              <>
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    GP / Consultant Details:
                  </label>
                  <div className='form__value'>{patient.gp_details}</div>
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    What concerns or issues do you have that leads you to seek
                    an appointment with Allergy4All?
                  </label>
                  <div className='form__value'>
                    {
                      patient.what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a
                    }
                  </div>
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    What allergy do you think you might have and why?
                  </label>
                  <div className='form__value'>
                    {patient.what_allergy_do_you_think_you_might_have_and_why}
                  </div>
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    What are you hoping to achieve at your consultation with
                    Allergy4All?
                  </label>
                  <div className='form__value'>
                    {
                      patient.what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg
                    }
                  </div>
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <h5
                    style={{
                      marginBottom: '15px',
                      fontSize: '20px',
                      fontWeight: 600,
                    }}>
                    Do you suffer from any of the following?
                  </h5>
                  {['Hayfever', 'Asthma', 'Eczema'].map((item, idx) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                      }}
                      key={idx}>
                      <label style={{ fontSize: '20px', fontWeight: 600 }}>
                        {item}:
                      </label>
                      <div className='form__value'>
                        {patient[`is_${item.toLowerCase()}`] ? 'Yes' : 'No'}
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <label style={{ fontSize: '20px', fontWeight: 600 }}>
                    Do you have any known allergies?
                  </label>
                  <div className='form__value'>
                    {patient.known_allergies_description}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </StaticPage>
  );
};

const mapStateToProps = ({ appointmentAdmin }) => ({
  loading: appointmentAdmin.patientsLoading,
  patient: appointmentAdmin.patient,
});

const mapDispatchToProps = (dispatch) => ({
  getPatient: async (data) => await dispatch(getPatientByIdAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointment);
