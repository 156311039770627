import React, { PureComponent } from 'react'
import { EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { bool, func, any, objectOf } from 'prop-types'
import { connect } from 'react-redux'
import StaticPage from '../../../../components/StaticPage'
import TextEditor from '../components/TextEditor'
import toBase64 from '../../../../utils/toBase64'
import { createArticleAction, updateArticleAction } from '../actions'
import { errorNotificationAction, successNotificationAction } from '../../../../containers/Notification/actions'
import { ROUTE_TO_BLOG_ADMIN } from '../../../../constants/routes'
import config from '../../../../config'

class ArticlePageAdmin extends PureComponent {
  constructor(props) {
    super(props)

    const { state, pathname } = this.props.history.location
    const pageType = pathname.split('/').pop()
    const isEdit = pageType === 'edit' && state

    if (!isEdit) {
      this.state = {
        isEdit: false,
        coverImage: null,
        coverBase64: null,
        title: null,
        attachment: null,
        videoUrl: '',
        editorState: EditorState.createEmpty(),
        contentState: EditorState.createEmpty(),
        errors: {},
      }
    } else {
      const { title, content, icon, id, attachment, video_url } = state

      const blocksFromHtml = htmlToDraft(content)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)

      this.state = {
        isEdit: true,
        imageFromUrl: icon.url,
        coverImage: icon.url,
        coverBase64: null,
        title,
        editorState,
        contentState,
        attachment: null,
        attachmentFromUrl: attachment.url,
        prevContent: content,
        videoUrl: video_url,
        errors: {},
        id,
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.coverImage !== this.state.coverImage) {
      const { coverImage } = this.state
      coverImage && toBase64(coverImage).then((res) => this.setState({ coverBase64: res }))
    }
  }

  onSaveHandler = async () => {
    const {
      contentState,
      title,
      coverImage,
      isEdit,
      id,
      prevContent,
      attachment,
      videoUrl,
      imageFromUrl,
      attachmentFromUrl,
    } = this.state
    const { history, createArticle, updateArticle, successNotification, errorNotification } = this.props

    try {
      const content = draftToHtml(contentState) || prevContent

      const newErrors = {}
      if (!title) newErrors.title = 'required'

      if (Object.keys(newErrors).length) return this.setState({ errors: newErrors })

      const formData = new FormData()
      formData.append('blog[title]', title)
      formData.append('blog[video_url]', videoUrl)
      formData.append('blog[content]', content || '')
      !imageFromUrl && formData.append('blog[icon]', coverImage)
      !attachmentFromUrl && formData.append('blog[attachment]', attachment)

      // eslint-disable-next-line no-unused-expressions
      isEdit ? await updateArticle(id, formData) : await createArticle(formData)

      const message = isEdit ? 'Article was successfully updated' : 'Article was successfully created'
      successNotification(message)

      history.push(ROUTE_TO_BLOG_ADMIN)
    } catch (e) {
      console.error(e)
      errorNotification()
    }
  }

  render() {
    const {
      title,
      coverImage,
      coverBase64,
      editorState,
      contentState,
      imageFromUrl,
      isEdit,
      errors,
      attachment,
      videoUrl,
      attachmentFromUrl,
    } = this.state
    const { history } = this.props

    const renderImage = () => {
      if (imageFromUrl && coverImage) {
        return (
          <img
            className='image article-cover-image'
            src={`${config.REACT_APP_UPLOADS_URL}${coverImage}`}
            title='Remove image'
            alt='ArticleImage'
            onClick={() => this.setState({ coverImage: null, imageFromUrl: false })}
          />
        )
      }
      if (coverBase64) {
        return (
          <div
            className='form--row f form--row_file2'
            onClick={() => this.setState({ coverBase64: null, coverImage: null })}
            title='Remove this picture'>
            <img src={coverBase64} className='article-cover-image' alt='Article cover' />
          </div>
        )
      }
      return (
        <div className='form--row f form--row_file2'>
          <div className='form--label'>Choose the main pic for Article</div>
          <button className='btns btn-b btn-fileupload2' type='button'>
            <i className='icons i-attachment' />
            Add file
            <input
              className='form__file'
              type='file'
              accept='.jpg,.jpeg,.png'
              name='coverImage'
              onChange={(e) => this.setState({ coverImage: e.target.files[0] })}
            />
          </button>
        </div>
      )
    }

    let fileUploaderContent = ''
    if (isEdit && attachmentFromUrl) {
      fileUploaderContent = (
        <div className='form__file-btn btns btn-b form__file-uploaded'>
          <i className='icons i-attachment' />
          {attachmentFromUrl.split('/').slice(-1).join('').substr(0, 33)}
          <i
            className='icons i24x24 i-close_red delete-attachment'
            title='Delete'
            onClick={() => this.setState({ attachmentFromUrl: null })}
          />
        </div>
      )
    } else {
      fileUploaderContent = attachment ? (
        <div className='form__file-btn btns btn-b form__file-uploaded'>
          <i className='icons i-attachment' />
          {attachment.name.substr(0, 27)}
          <i
            className='icons i24x24 i-close_red delete-attachment'
            title='Delete'
            onClick={() => this.setState({ attachment: null })}
          />
        </div>
      ) : (
        <button className='form__file-btn btns btn-b' type='button'>
          <i className='icons i-attachment' />
          Attach pdf
          <input
            className='form__file'
            id='attachment'
            type='file'
            accept='.pdf'
            name='attachment'
            value={undefined}
            onChange={(e) => this.setState({ attachment: e.target.files[0] })}
          />
        </button>
      )
    }
    return (
      <StaticPage pageClassName='blog__page' isAdmin noFooter>
        <div className='site__width'>
          <section className='blog--edit section--content'>
            <form className='form--default'>
              <header className='blog--edit_header clearfix'>
                <div className='blog--edit_header-text'>
                  <h1 className='blog--edit_header-title form--title uppercase'>
                    {isEdit ? 'Edit article' : 'Create new article'}
                  </h1>
                </div>
              </header>
              <div className='blog--edit_content'>
                <div className='form--column'>
                  {renderImage()}
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='title'>
                      Tittle of the Article
                    </label>
                    <input
                      className='form--input'
                      id='title'
                      type='text'
                      name='title'
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value, errors: { ...errors, title: null } })}
                    />
                    {errors.title ? <p className='validation-error'>Title is required</p> : null}
                  </div>
                  <div className='form__row attachment'>{fileUploaderContent}</div>
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='videoUrl'>
                      Video url
                    </label>
                    <input
                      className='form--input'
                      id='title'
                      type='text'
                      name='videoUrl'
                      value={videoUrl}
                      onChange={(e) => this.setState({ videoUrl: e.target.value })}
                    />
                  </div>
                </div>
                <TextEditor
                  onStateChange={(state) => this.setState({ editorState: state })}
                  editorState={editorState}
                  onContentChange={(state) => this.setState({ contentState: state, errors: { ...errors } })}
                  contentState={contentState}
                  error={errors.content}
                />
                <div className='form--row form--row_submit'>
                  <button
                    type='button'
                    className='btns btn-i reset_btn'
                    onClick={() => history.push(ROUTE_TO_BLOG_ADMIN)}>
                    CANCEL
                  </button>
                  <button type='button' className='btns btn-i submit_btn' onClick={this.onSaveHandler}>
                    Create new
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </StaticPage>
    )
  }
}

const mapStateToProps = ({ adminBlog }) => ({
  loading: adminBlog.articleLoading,
  errors: adminBlog.errors,
})

const mapDispatchToProps = (dispatch) => ({
  createArticle: async (data) => await dispatch(createArticleAction(data)),
  updateArticle: async (id, data) => await dispatch(updateArticleAction(id, data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
})

ArticlePageAdmin.propTypes = {
  loading: bool,
  createArticle: func.isRequired,
  updateArticle: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  history: objectOf(any),
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePageAdmin)
