import typeToReducer from 'type-to-reducer'
import { GET_ARTICLE_BY_ID, GET_BLOGS } from './constants'

export const initialState = {
  blogLoading: false,
  blogs: [],
  blogPages: 0,
  errors: [],
  articleLoading: false,
  article: null,
}

export default typeToReducer(
  {
    [GET_BLOGS]: {
      START: (state, action) => {
        return {
          ...state,
          blogLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          blogs: action.payload.data && action.payload.data.blogs,
          blogPages: action.payload.data && action.payload.data.pages,
          blogLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          blogLoading: false,
        }
      },
    },
    [GET_ARTICLE_BY_ID]: {
      START: (state, action) => {
        return {
          ...state,
          articleLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          article: action.payload.data.blog,
          articleLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          articleLoading: false,
        }
      },
    },
  },
  initialState
)
