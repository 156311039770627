export default (data, fileName) => {
  const downloadUrl = URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = downloadUrl
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function downloadCsv(data) {
  const blob = new Blob(data)
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, `Appointments.csv`)
  } else {
    const a = window.document.createElement('a')

    a.href = window.URL.createObjectURL(blob, {
      type: 'text/plain',
    })
    a.download = `Appointments.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
