import React, { useEffect } from 'react'
import { any, arrayOf, func, objectOf, bool, object } from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import PDFViewer from 'pdf-viewer-reactjs'
import { isMobile } from 'react-device-detect'

import StaticPage from '../../../components/StaticPage'
import { getArticleByIdAction } from '../actions'
import Loader from '../../../components/Loader'
import MoreArticles from './MoreArticles'
import config from '../../../config'

const ArticlePage = ({ article, articleLoading, getArticle, match, errors }) => {
  const { slug: queryId } = match.params

  useEffect(() => {
    getArticle(queryId)
  }, [queryId, getArticle])

  if (!articleLoading && article) {
    const {
      title,
      content,
      published_at,
      icon: { url },
      id,
      attachment,
      video_url,
    } = article

    const resizeArticle = (zoomIndex) => {
      const el = document.querySelector('.article__content')

      if (zoomIndex === 1) {
        el.style.maxWidth = '660px'
        return
      }
      el.style.maxWidth = '100%'
    }

    const publishedAt = moment(published_at).format('ll')
    // eslint-disable-next-line no-undef
    const currentUrl = window.location.href
    return (
      <StaticPage pageClassName='articleFull__page'>
        <div className='site__width'>
          <h1 className='page__title'>{title}</h1>
          <article className='article__content'>
            <div className='article__content--date'>{publishedAt}</div>
            {url ? (
              <figure className='article__content--image'>
                <img className='image' src={`${config.REACT_APP_UPLOADS_URL}${url}`} alt='ArticleImage' />
              </figure>
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {video_url ? (
              <div className='article-video'>
                <iframe
                  src={video_url}
                  width={isMobile ? '345px' : '100%'}
                  height={isMobile ? '193px' : '350px'}
                  frameBorder='0'
                  allow='autoplay; fullscreen'
                  allowFullScreen
                  title='Demo'
                />
              </div>
            ) : null}
            {attachment && attachment.url ? (
              <div>
                <PDFViewer
                  document={{ url: `${config.REACT_APP_UPLOADS_URL}${attachment.url}` }}
                  loader
                  onZoom={resizeArticle}
                />
              </div>
            ) : null}

            <div className='article__share'>
              <ul className='article__share--list'>
                <li className='article__share--item'>
                  <div className='btns btn-b'>
                    <FacebookShareButton quote={title} url={currentUrl}>
                      <i className='icons i24x24 i-facebook' />
                    </FacebookShareButton>
                  </div>
                </li>
                <li className='article__share--item'>
                  <div className='btns btn-b'>
                    <TwitterShareButton quote={title} url={currentUrl}>
                      <i className='icons i24x24 i-twitter' />
                    </TwitterShareButton>
                  </div>
                </li>
                <li className='article__share--item'>
                  {
                    // TODO Set valid email here
                  }
                  <a className='btns btn-b' href='mailto: a4a@gmail.com'>
                    <i className='icons i24x24 i-gmail' />
                  </a>
                </li>
              </ul>
            </div>
          </article>
          <MoreArticles articleId={id} />
        </div>
      </StaticPage>
    )
  }
  if (!articleLoading && errors.length) {
    return <div>{errors[0].message}</div>
  }
  return (
    <StaticPage pageClassName='articleFull__page'>
      <div className='site__width'>
        <Loader />
      </div>
    </StaticPage>
  )
}

ArticlePage.propTypes = {
  article: objectOf(any),
  articleLoading: bool.isRequired,
  getArticle: func.isRequired,
  errors: arrayOf(object),
  match: objectOf(any).isRequired,
}

const mapStateToProps = ({ blog }) => ({
  article: blog.article,
  articleLoading: blog.articleLoading,
  errors: blog.errors,
})

const mapDispatchToProps = (dispatch) => ({
  getArticle: async (id) => await dispatch(getArticleByIdAction(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage)
