import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  ROUTE_TO_ALLERGY_TEST,
  routeToBookTest,
} from '../../../constants/routes';
import { BOOKING_TYPE_TEST } from '../../../constants';
import MainButton from '../../MainButton';
import StaticPage from '../../StaticPage';
import HeroImage from '../../HeroImage';

export default function Home() {
  return (
    <StaticPage withoutClassPage>
      {/* Hero */}
      <section className='hero__section'>
        <div className='site__width'>
          <div className='hero__image'>
            <HeroImage />
          </div>
          <div className='hero__info'>
            <h2 className='hero__info--title'>Welcome to</h2>
            <div className='hero__info--logo'>
              <i className='icons logo' />
            </div>
            <div className='hero__info--text'>
              <p>
                The Allergy4All team aims to diagnose and treat patients
                suffering from allergies while informing, educating and advising
                on the management of various food and airborne allergies.
              </p>
            </div>
            <div className='hero__info--btns'>
              <MainButton
                to={routeToBookTest(BOOKING_TYPE_TEST)}
                title='Book An Appointment'
              />
            </div>
          </div>
        </div>
      </section>
      {/* Hero */}

      {/* WWD */}
      <section className='aboutus__section'>
        <div className='site__width'>
          <h2 className='section__title'>What we do</h2>
          <div className='section__subtitle'>
            <p>
              We provide allergy skin prick testing to all ages, from infant to
              adult.
            </p>
            <p>
              A skin prick test is a diagnostic method for the diagnosis of
              allergies.
            </p>
            <p>
              The aim of an allergy test is to trigger a minor yet controlled
              allergic reaction.
            </p>
          </div>
          <div className='section__content'>
            <Link
              className='aboutus__section--link btns btn-i'
              to={ROUTE_TO_ALLERGY_TEST}
              title='How is the Skin Prick Test carried out'>
              How is the Skin Prick Test carried out?{' '}
              <i className='icons i24x12 i-arrow_right' />
            </Link>
            <div className='aboutus__section--list'>
              <div className='aboutus__section--item'>
                <div className='aboutus__section--video video__block'>
                  <div className='btn-video'>
                    <iframe
                      src='https://player.vimeo.com/video/402875228'
                      width={isMobile ? '345px' : '853px'}
                      height={isMobile ? '193px' : '480px'}
                      frameBorder='0'
                      allow='autoplay; fullscreen'
                      allowFullScreen
                      title='Demo'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /WWD */}

      {/* Appointment */}
      <section className='appointment__section'>
        <div className='site__width'>
          <div className='appointment__section--title'>
            If you or your child are suffering with allergies, we are here to
            help.
          </div>
          <div className='appointment__section--btns'>
            <MainButton
              to={routeToBookTest(BOOKING_TYPE_TEST)}
              title='Book an Allergy Test'
            />
          </div>
        </div>
      </section>
      {/* Appointment */}
    </StaticPage>
  );
}
