import typeToReducer from 'type-to-reducer';
import { GET_FAQ_LANDING } from './constants';

export const initialState = {
  loading: false,
  faqs: [],
  pages: 0,
  count: 0,
  errors: [],
};

export default typeToReducer(
  {
    [GET_FAQ_LANDING]: {
      START: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          faqs: action.payload.data && action.payload.data.fa_questions,
          pages: action.payload.data && action.payload.data.pages,
          count: action.payload.data && action.payload.data.count,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
  },
  initialState
);
