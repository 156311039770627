import axios from 'axios';
import config from '../config';
import getToken from './getToken';
import { ROUTE_TO_LOGIN } from '../constants/routes';

const api = axios.create({
  baseURL: config.REACT_APP_BASE_URL,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: {
      toString: () => JSON.parse(getToken()),
    },
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location = ROUTE_TO_LOGIN;
    }
    return Promise.reject(error);
  }
);

export default api;
