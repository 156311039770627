import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Pagination from 'react-paginate'
import { any, arrayOf, bool, func, number, object, objectOf, string } from 'prop-types'
import StaticPage from '../../components/StaticPage'
import BlogList from './components/BlogList'
import Loader from '../../components/Loader'
import { getBlogsAction } from './actions'

const BlogPage = (props) => {
  const { loading, blogs, pages, errors, getBlogs, location } = props

  const page = location.search.substr(-1) || 1

  useEffect(
    // eslint-disable-next-line no-shadow
    () => {
      getBlogs(page)
    },
    [page, getBlogs]
  )

  const onPageChange = ({ selected }) => {
    const { history } = props

    history.push(`${history.location.pathname}?page=${+selected + 1}`) // value instead index
  }

  if (!loading && errors.length) {
    return <div>{errors[0].message}</div>
  }

  return (
    <StaticPage pageClassName='articlesList__page'>
      <div className='site__width'>
        <h1 className='page__title'>Blog</h1>
        <div className='page__subtitle'>Our collection of articles about Allergy.</div>
        {loading ? <Loader /> : <BlogList blogs={blogs} pages={pages} />}
        <div className='pagination'>
          <Pagination
            pageCount={pages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            initialPage={page && page - 1}
            containerClassName='pagination__list'
            pageClassName='pagination__list--item'
            pageLinkClassName='pointer'
            activeClassName='current'
            previousClassName='hidden'
            nextClassName='hidden'
            breakClassName='ellipsis inline'
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ blog }) => {
  return {
    loading: blog.blogLoading,
    blogs: blog.blogs,
    pages: blog.blogPages,
    errors: blog.errors,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBlogs: async (page) => await dispatch(getBlogsAction(page)),
})

BlogPage.propTypes = {
  loading: bool.isRequired,
  blogs: arrayOf(object).isRequired,
  pages: number.isRequired,
  getBlogs: func.isRequired,
  history: objectOf(any),
  location: objectOf(string),
  errors: arrayOf(object),
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage)
