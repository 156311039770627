/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import moment from 'moment'
import { func, bool, any, objectOf } from 'prop-types'
import testSchema from './validate'
import DatePicker from '../../../../../components/DatePicker'
import config from '../../../../../config'
import downloadBlob from '../../../../../utils/downloadBlob'

const phoneRegExp = /^\p{L}+$/u

// eslint-disable-next-line react/prefer-stateless-function
class BookingForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = !this.props.isEditing
      ? {
        firstName: '',
        surname: '',
        birthDate: undefined,
        birthIsTouched: false,
        isChild: this.props.isChild,
        parentFirstName: '',
        parentSurname: '',
        email: '',
        tel: '',
        GPName: '',
        GPAddress: '',
        GPEmail: '',
        testReason: '',
        paymentType: '',
        GPAttachment: null,
      }
      : {
        firstName: this.props.patientData.first_name,
        surname: this.props.patientData.last_name,
        birthDate: moment(this.props.patientData.dob, 'DD/MM/YYYY').toDate(),
        birthIsTouched: true,
        isChild: !this.props.patientData.is_adult,
        parentFirstName: this.props.patientData.guardian_first_name,
        parentSurname: this.props.patientData.guardian_last_name,
        email: this.props.patientData.email,
        tel: this.props.patientData.phone,
        GPName: this.props.patientData.consultant_name,
        GPAddress: this.props.patientData.consultant_addres,
        GPEmail: this.props.patientData.consultant_email,
        testReason: this.props.patientData.allergens,
        paymentType: this.props.patientData.payment_type,
        GPAttachment: this.props.patientData.referral_letter && this.props.patientData.referral_letter.url,
        resultAttachment: this.props.patientData.result,
        resultsWasEdited: false,
        GPAttachmentWasEdited: false
      }
  }

  onChangeHandler = (e) => {
    const {name, value} = e.target
    if (name === 'birthDate' || name === 'GPAttachment' || name === 'resultAttachment') return
    this.setState({[name]: value})
  }

  render() {
    const {birthDate, birthIsTouched, GPAttachment, resultAttachment, resultsWasEdited, GPAttachmentWasEdited} = this.state

    const {isChild, isVideo, onFormSubmit, patientData, isEditing, getResults, bookedByClient} = this.props // on edit page if patient data existed

    let initialData
    if (patientData) {
      // eslint-disable-next-line react/prop-types
      const {
        first_name,
        last_name,
        guardian_first_name,
        guardian_last_name,
        phone,
        email,
        consultant_name,
        consultant_addres,
        consultant_email,
        referral_letter,
        payment_type,
        allergens,
        result,
      } = patientData

      initialData = {
        firstName: first_name,
        surname: last_name,
        isChild,
        parentFirstName: guardian_first_name,
        parentSurname: guardian_last_name,
        email,
        tel: phone,
        GPName: consultant_name,
        GPAddress: consultant_addres,
        GPEmail: consultant_email,
        GPAttachment: referral_letter,
        paymentType: payment_type,
        testReason: allergens,
        resultAttachment: result,
        isVideoCall: isVideo
      }
    }

    let fileUploaderContent = ''
    if (patientData.result && !resultsWasEdited) {
      fileUploaderContent = (
        <button type='button' className='form__file-btn btns btn-b form__file-uploaded'>
          <i className='icons i-attachment admin-attachment-icon'/>
          <span
            title='Download results'
            onClick={() => getResults(patientData.id).then(({value}) => downloadBlob(value.data, patientData.result))}
            style={{cursor: 'pointer', fontWeight: 600, textDecoration: 'underline'}}>
            Results
          </span>
          <i
            className='icons i24x24 i-close_red delete-attachment admin-attachment-icon'
            title='Delete'
            onClick={() => this.setState({resultAttachment: null, resultsWasEdited: true})}
          />
        </button>
      )
    } else {
      fileUploaderContent = resultAttachment ? (
        <button type='button' className='form__file-btn btns btn-b form__file-uploaded'>
          <i className='icons i-attachment admin-attachment-icon'/>
          {resultAttachment.name.substr(0, 27)}
          <i
            className='icons i24x24 i-close_red delete-attachment admin-attachment-icon'
            title='Delete'
            onClick={() => this.setState({resultAttachment: null})}
          />
        </button>
      ) : (
        <button className='form__file-btn btns btn-b' type='button'>
          <i className='icons i-attachment'/>
          Upload results
          <Field
            className='form__file'
            id='resultAttachment'
            type='file'
            accept='.doc,.docx,.pdf'
            name='resultAttachment'
            value={undefined}
            onChange={(e) => this.setState({resultAttachment: e.target.files[0]})}
          />
        </button>
      )
    }

    let GPAttachmentUploaderContent = ''
    if (patientData.referral_letter && patientData.referral_letter.url && !GPAttachmentWasEdited) {
      GPAttachmentUploaderContent = GPAttachment ? (
        <div className='form__row attachment'>
          <button
            type='button'
            className='form__file-btn btns btn-b form__file-uploaded'
            onClick={() => window.open(`${config.REACT_APP_UPLOADS_URL}${GPAttachment}`)}
            style={{cursor: 'pointer', fontWeight: 700, textDecoration: 'underline', display: 'inline-block'}}>
            <i className='icons i-attachment'/>
            Download the referral letter
          </button>
          <i
            className='icons i24x24 i-close_red delete-attachment admin-attachment-icon'
            title='Delete'
            onClick={() => this.setState({GPAttachment: null, GPAttachmentWasEdited: true})}
          />
        </div>
      ) : null
    } else {
      GPAttachmentUploaderContent = GPAttachment ? (
        <div className='form__file-btn btns btn-b form__file-uploaded'>
          <i className='icons i-attachment' />
          {GPAttachment.name.substr(0, 27)}
          <i
            className='icons i24x24 i-close_red delete-attachment'
            title='Delete'
            onClick={() => this.setState({ GPAttachment: null })}
          />
        </div>
      ) : (
        <button className='form__file-btn btns btn-b' type='button'>
          <i className='icons i-attachment' />
          Upload referral letter
          <Field
            className='form__file'
            id='GPAttachment'
            type='file'
            accept='.doc,.docx,.pdf'
            name='GPAttachment'
            value={undefined}
            onChange={(e) => this.setState({ GPAttachment: e.target.files[0] })}
          />
        </button>
      )
    }


    return (
      <Formik
        enableReinitialize
        initialValues={
          isEditing
            ? initialData
            : {
              firstName: '',
              surname: '',
              isChild,
              parentFirstName: '',
              parentSurname: '',
              email: '',
              tel: '',
              GPName: '',
              GPAddress: '',
              GPEmail: '',
              GPAttachment: '',
              testReason: '',
              paymentType: '',
              isVideoCall: isVideo
            }
        }
        validationSchema={testSchema}
        onSubmit={async (values, {setSubmitting, resetForm, setStatus}) => {
          if (!birthDate) {
            return this.setState({birthIsTouched: true})
          }

          try {
            setSubmitting(true)
            await onFormSubmit(this.state)

            resetForm()
            setSubmitting(false)
            setStatus({success: true})
          } catch (error) {
            setStatus({success: false})
            setSubmitting(false)
          }
        }}>
        {({values, isSubmitting}) => {
          return (
            <div className='form--column'>
              <Form className='form__default' onChange={this.onChangeHandler} id='create-patient-form'>
                <h2 className='form--title2'>Patient info</h2>
                <div className='form__row'>
                  <label className='form--label' htmlFor='firstName'>
                    Patient Firstname
                  </label>
                  <Field name='firstName' className='form--input admin-form--input' placeholder='Patient First Name'/>
                  <ErrorMessage name='firstName' component='div' className='validation-error'/>
                </div>

                <div className='form__row'>
                  <label className='form--label' htmlFor='surname'>
                    Patient Surname
                  </label>
                  <Field name='surname' className='form__input admin-form--input' placeholder='Patient Surname'/>
                  <ErrorMessage name='surname' component='div' className='validation-error'/>
                </div>

                <div className='form__row calendar'>
                  <DatePicker
                    date={birthDate}
                    setDate={(value) => this.setState({birthDate: value})}
                    touch={() => this.setState({birthIsTouched: true})}
                    isAdmin
                  />
                  {!birthDate && birthIsTouched ? <div className='validation-error'>Required</div> : null}
                </div>

                {isChild ? (
                  <>
                    <div className='form__row parent'>
                      <label className='form--label' htmlFor='parentFirstName'>
                        Parent/Guardian Firstname
                      </label>
                      <Field
                        name='parentFirstName'
                        className='form__input admin-form--input'
                        placeholder='First name Parent / Guardian'
                      />
                      <ErrorMessage name='parentFirstName' component='div' className='validation-error'/>
                    </div>
                    <label className='form--label' htmlFor='parentSurname'>
                      Parent/Guardian Surname
                    </label>
                    <div className='form__row parent'>
                      <Field
                        name='parentSurname'
                        className='form__input admin-form--input'
                        placeholder='Surname Parent / Guardian'
                      />
                      <ErrorMessage name='parentSurname' component='div' className='validation-error'/>
                    </div>
                  </>
                ) : null}

                <div className='form__row'>
                  <label className='form--label' htmlFor='email'>
                    Contact Email
                  </label>
                  <Field name='email' className='form__input admin-form--input' placeholder='Contact Email'/>
                  <ErrorMessage name='email' component='div' className='validation-error'/>
                </div>

                <div className='form__row'>
                  <label className='form--label' htmlFor='tel'>
                    Contact Phone
                  </label>
                  <Field
                    type='string'
                    onKeyDown={(e) => {
                      if (e.key.length === 1 && phoneRegExp.test(e.key)) {
                        // allow control keys, numbers, and symbols
                        e.preventDefault()
                        e.stopPropagation()
                      }
                    }}
                    name='tel'
                    className='form__input admin-form--input'
                    placeholder='Contact Phone Number'
                  />
                  <ErrorMessage name='tel' component='div' className='validation-error'/>
                </div>
                {!isVideo ? (
                  <>
                    {!isChild ? (
                      <>
                        <div className='form__row'>
                          <label className='form--label' htmlFor='GPName'>
                            GP / Consultant Name
                          </label>
                          <Field
                            className='form__input admin-form--input'
                            name='GPName'
                            placeholder='GP / Consultant Name'
                          />
                          <ErrorMessage name='GPName' component='div' className='validation-error'/>
                        </div>
                        <div className='form__row'>
                          <label className='form--label' htmlFor='GPAddress'>
                            GP / Consultant Address
                          </label>
                          <Field
                            className='form__input admin-form--input'
                            name='GPAddress'
                            placeholder='GP / Consultant Address'
                          />
                          <ErrorMessage name='GPAddress' component='div' className='validation-error'/>
                        </div>
                        <div className='form__row'>
                          <label className='form--label' htmlFor='GPEmail'>
                            GP / Consultant Email
                          </label>
                          <Field
                            className='form__input admin-form--input'
                            type='email'
                            name='GPEmail'
                            placeholder='GP / Consultant Email'
                          />
                          <ErrorMessage name='GPEmail' component='div' className='validation-error'/>
                        </div>
                      </>
                    ) : null}


                    <div className='form__row attachment'>
                      {GPAttachmentUploaderContent}
                    </div>

                    <div className='form__row'>
                      <Field
                        as='textarea'
                        className='form__textarea admin-form--input'
                        name='testReason'
                        placeholder='Please enter what you need to be tested for (e.g. dairy, nuts, dust mites, dog dander).'
                      />
                      <ErrorMessage name='testReason' component='div' className='validation-error'/>
                    </div>
                    {isEditing && !bookedByClient ? (
                      <div className='form__row'>
                        <label className='form--label' htmlFor='paymentType'>
                          Payment type
                        </label>
                        <Field
                          className='form__input admin-form--input'
                          type='text'
                          name='paymentType'
                          placeholder='Payment type'
                        />
                        <ErrorMessage name='paymentType' component='div' className='validation-error'/>
                      </div>
                    ) : null}
                    {isEditing ? <div className='form__row attachment'>{fileUploaderContent}</div> : null}
                  </>
                ) : null}
                <Field name='appointmentType' className='form__input admin-form--input hidden'/>
              </Form>
            </div>
          )
        }}
      </Formik>
    )
  }
}

BookingForm.propTypes = {
  isChild: bool.isRequired,
  isVideo: bool.isRequired,
  isEditing: bool.isRequired,
  bookedByClient: bool.isRequired,
  onFormSubmit: func.isRequired,
  getResults: func.isRequired,
  patientData: objectOf(any),
}

export default BookingForm
