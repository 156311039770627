import React, { useEffect } from 'react';
import StaticPage from '../../StaticPage';
import FaqList from './components/FaqList';
import { getFaqLandingAction } from './actions';
import { DEFAULT_PER_PAGE_FAQ_LANDING } from './constants';
import { connect } from 'react-redux';

const FaqPage = ({ getFaqs, faqs }) => {
  useEffect(() => {
    getFaqs({ page: 1, per_page: DEFAULT_PER_PAGE_FAQ_LANDING });
  }, []);

  return (
    <StaticPage pageClassName='faqs__page'>
      <div className='site__width'>
        <h1 className='page__title'>FAQs</h1>
        <div className='page__content'>
          <FaqList items={faqs} />
        </div>
      </div>
    </StaticPage>
  );
};

const mapStateToProps = ({ faqLanding }) => ({
  faqs: faqLanding.faqs,
});

const mapDispatchToProps = (dispatch) => ({
  getFaqs: () => dispatch(getFaqLandingAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
