import React from 'react';

export default [
  {
    name: <div className='table-header-item'>Title</div>,
    selector: 'title',
  },
  {
    name: <div className='table-header-item'>Answer</div>,
    selector: (row) =>
      row.answer.length > 50 ? row.answer.slice(0, 50) + '...' : row.answer,
    grow: 2,
  },
];
