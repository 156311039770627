import React, { useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import StaticPage from '../../../components/StaticPage'
import Calendar from '../../../components/Calendar'
import { APPOINTMENT_TYPES_ADMIN, SESSION_TYPES } from '../../../constants/companyData'
import { getMonthSlotsAction, setBookingDataAction } from '../../../containers/Booking/actions'
import {
  getSessionsAdminAction,
  updateSessionsAdminAction,
  deleteSessionAdminAction,
  bookByAdminAction,
} from './actions'
import { errorNotificationAction, successNotificationAction } from '../../../containers/Notification/actions';

const AvailabilityPage = (props) => {
  const [sessionType, setSessionType] = useState(null)
  const [age, setAge] = useState(null)

  const isVideo = sessionType && sessionType.value === 'video'

  const typeSelectContent = (
    <div className='availabilitys__main_select'>
      <Select name='age' id='age' options={SESSION_TYPES} onChange={setSessionType} placeholder='Choose session type' />
    </div>
  )

  const ageSelectContent = (
    <div className='availabilitys__main_select'>
      <Select
        name='age'
        id='age'
        options={APPOINTMENT_TYPES_ADMIN}
        onChange={setAge}
        placeholder='Choose appointment type'
      />
    </div>
  )

  return (
    <StaticPage isAdmin noFooter pageClassName='availabilitys__page'>
      <div className='site__width'>
        <section className='availabilitys--new section--content'>
          <div className='form--default'>
            <h2 className='form--title availabilitys-title'>Availability settings</h2>
            {typeSelectContent}
            {sessionType ? ageSelectContent : null}
            {age ? (
              <div className='availabilitys_content'>
                {/* eslint-disable-next-line react/jsx-props-no-spreading,react/destructuring-assignment */}
                <Calendar age={age.value} isVideo={isVideo} isAdmin sessionsLoading={props.loading} {...props} />
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ booking, availability }) => ({
  monthLoading: booking.monthLoading,
  loading: availability.loading,
  monthSlots: booking.monthSlots,
  sessions: availability.sessions,
  availabilityErrors: availability.errors,
  errors: booking.errors,
})

const mapDispatchToProps = (dispatch) => ({
  getMonthSlots: (data) => dispatch(getMonthSlotsAction(data)),
  getSessions: (data) => dispatch(getSessionsAdminAction(data)),
  updateSessions: (data) => dispatch(updateSessionsAdminAction(data)),
  deleteSession: (id) => dispatch(deleteSessionAdminAction(id)),
  bookSessionByAdmin: (id, data) => dispatch(bookByAdminAction(id, data)),
  setBookingData: (item) => dispatch(setBookingDataAction(item)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityPage)
