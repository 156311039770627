import { createAction } from 'redux-actions'

import {
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_EXPORTED_TABLE,
  PAYMENT_REFUND,
  GET_APPOINTMENT_BY_ID,
} from './constants'
import {
  getAppointmentsApi,
  deleteAppointmentApi,
  paymentRefundApi,
  getExportedTableApi,
  getAppointmentByIdApi,
} from './api'

export const getAppointmentsAction = createAction(GET_APPOINTMENTS, async (data) => await getAppointmentsApi(data))
export const getAppointmentByIdAction = createAction(
  GET_APPOINTMENT_BY_ID,
  async (data) => await getAppointmentByIdApi(data)
)
export const getExportedTableAction = createAction(GET_EXPORTED_TABLE, async (data) => await getExportedTableApi(data))
export const deleteAppointmentAction = createAction(DELETE_APPOINTMENT, async (id) => await deleteAppointmentApi(id))
export const paymentRefundAction = createAction(
  PAYMENT_REFUND,
  async (transaction_id) => await paymentRefundApi(transaction_id)
)
