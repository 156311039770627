import React from 'react';
import moment from 'moment';

export default [
  {
    name: <div className='table-header-item'>Patient name</div>,
    selector: 'full_name',
  },
  {
    name: <div className='table-header-item'>Email</div>,
    selector: 'email',
  },
  {
    name: <div className='table-header-item'>Date of birth</div>,
    cell: (row) => moment(row.dob).format('DD/MM/YYYY'),
  },
  {
    name: <div className='table-header-item'>Phone</div>,
    cell: (row) => row.phone,
  },
];
