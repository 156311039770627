import React from 'react';
import MapComponent from '../Map';

const CENTER_FOR_BLACKROCK_COORDINATES = { lat: 53.30828, lng: -6.21671 };
const BLACKROCK_CLINIC_COORDINATES = { lat: 53.305152, lng: -6.188182 };
const BEACON_HOSPITAL_COORDINATES = { lat: 53.276995, lng: -6.219493 };
const ZOOM = 12;

export default function Footer() {
  return (
    <footer className='footer__main'>
      <div className='site__width footer__width'>
        <div className='footer__links'>
          <div className='footer-contact-wrapper'>
            <div className='footer__links--contacts'>
              <div className='footer__title'>Contact us</div>
              <ul>
                <li>
                  <a
                    className='btns btn-i'
                    href='tel: 086 0736297'
                    title='Phone'>
                    <i className='icons i24x24 i-phone_rnd i-rnd' />
                    086 0736297
                  </a>
                </li>
                <li>
                  <a
                    className='btns btn-i lowerCase'
                    href='mailto: info@allergy4all.ie'
                    title='Email'>
                    <i className='icons i24x24 i-email_rnd i-rnd' />
                    info@allergy4all.ie
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer__links--social'>
              <div className='footer__title'>Follow us</div>
              <ul>
                <li>
                  <a
                    className='btns btn-b'
                    href='https://twitter.com/allergy4all?lang=en'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <i className='icons i32x32 i-twitter_rnd i-rnd' />
                  </a>
                </li>
                <li>
                  <a
                    className='btns btn-b'
                    href='https://www.facebook.com/allergy4all/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <i className='icons i32x32 i-facebook_rnd i-rnd' />
                  </a>
                </li>
                <li>
                  <a
                    className='btns btn-b'
                    href='https://www.instagram.com/_allergy4all_/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <i className='icons i32x32 i-instagram_rnd i-rnd' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='footer__copyrights'>
            <div className='footer__copyrights--logo'>
              <i className='icons logo' />
            </div>
            <div className='footer__copyrights--text'>
              <p>© 2020 Allergy 4 All. All Rights Reserved.</p>
              <p>Designed by Devensis</p>
            </div>
          </div>
        </div>
        <div className='footer__maps'>
          <div className='footer__maps--item'>
            {/* <MapComponent
              isMarkerShow
              coordinates={BEACON_HOSPITAL_COORDINATES}
              zoom={ZOOM}
              googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBBIcXIrBHHJi2XpduCAlUgojXKOMskF0w&v=3.exp&libraries=geometry,drawing,places'
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className='footer__maps--map' style={{ height: '166px' }} />}
              mapElement={<div style={{ height: `100%` }} />}
              markers={[BEACON_HOSPITAL_COORDINATES]}
            /> */}
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2385.770555799173!2d-6.219076!3d53.2757306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x61c8f1c6549c32bf!2sBeacon%20Hospital!5e0!3m2!1sen!2sua!4v1647200827447!5m2!1sen!2sua'
              width='340'
              height='150'
              allowfullscreen=''
              loading='lazy'></iframe>
            <div className='footer__maps--text-wrapper'>
              <div className='footer__maps--text'>
                <a
                  className='footer__title btns btn-i'
                  href='https://g.page/BeaconHospital?share'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Get Directions'>
                  <i className='icons i16x16 i-geo_rnd i-rnd' /> Get directions
                </a>
                <p>
                  Suite 36, Beacon for Kids, Beacon Hospital, Sandyford, Dublin
                  18
                </p>
              </div>
              <div className='footer__maps--text'>
                <a
                  className='footer__title btns btn-i'
                  href='https://g.page/BeaconHospital?share'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Get Directions'>
                  <i className='icons i16x16 i-geo_rnd i-rnd' /> Get directions
                </a>
                <p>
                  Suite 12, Beacon Consultants Suites, Beacon Hospital,
                  Sandyford, Dublin 18
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
