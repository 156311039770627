import React from 'react';
import {
  ROUTE_TO_BLOG_ADMIN,
  ROUTE_TO_ALLERGY_TEST_ADMIN,
  ROUTE_TO_AVAILABILITY_ADMIN,
  ROUTE_TO_VIDEO_ADMIN,
  ROUTE_TO_TEAM_ADMIN,
  ROUTE_TO_FAQ_ADMIN,
} from '../../../constants/routes';
import NavItem from './components/NavItem';

const AdminNav = () => {
  const menuItems = [
    {
      title: 'Booking Appointments',
      path: ROUTE_TO_ALLERGY_TEST_ADMIN,
    },
    // {
    //   title: 'Video Consultation',
    //   path: ROUTE_TO_VIDEO_ADMIN,
    // },
    {
      title: 'Blog',
      path: ROUTE_TO_BLOG_ADMIN,
    },
    {
      title: 'Availability settings',
      path: ROUTE_TO_AVAILABILITY_ADMIN,
    },
    {
      title: 'Team',
      path: ROUTE_TO_TEAM_ADMIN,
    },
    {
      title: 'FAQ',
      path: ROUTE_TO_FAQ_ADMIN,
    },
  ];

  return (
    <ul className='header__main--menu'>
      {menuItems.map((item) => NavItem(item))}
    </ul>
  );
};

export default AdminNav;
