import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { any, func, objectOf } from 'prop-types';
import moment from 'moment';
import StaticPage from '../../../components/StaticPage';
import { ROUTE_TO_ROOT } from '../../../constants/routes';
import ConfirmationInfo from '../BookingPage/components/ConfirmPage';
import {
  createPatientAction,
  getPatientAction,
  initiatePaymentAction,
} from '../actions';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../../Notification/actions';
import BookingForm from '../BookingPage/components/BookingForm copy';
import { BOOKING_TYPE_VIDEO } from '../../../constants';
import BookingSelect from '../../../components/BookingSelect';
import {
  APPOINTMENT_TYPE,
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_VIDEO,
} from '../../../constants/companyData';

const BookingTest = ({
  bookingData,
  history,
  createPatient,
  patientData,
  getPatient,
  initiatePayment,
  successNotification,
  errorNotification,
  match,
}) => {
  const [isConfirmStep, setConfirmStep] = useState(false);
  const [hasReferralLetter, setHasReferralLetter] = useState(null);

  const onFormSubmit = async ({
    full_name,
    address,
    email,
    phone,
    gp_details,
    what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a,
    what_allergy_do_you_think_you_might_have_and_why,
    what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg,
    known_allergies_description,
    dob,
    attachment,
    is_hayfever,
    is_asthma,
    is_eczema,
  }) => {
    const formData = new FormData();
    formData.append('patient[is_referral]', hasReferralLetter);
    if (hasReferralLetter) {
      formData.append('patient[full_name]', full_name);
      formData.append('patient[email]', email);
      formData.append('patient[dob]', moment(dob).format('YYYY-MM-DD'));
      formData.append('patient[phone]', phone);
      formData.append('patient[attachment]', attachment);
    } else {
      formData.append('patient[full_name]', full_name);
      formData.append('patient[email]', email);
      formData.append('patient[dob]', moment(dob).format('YYYY-MM-DD'));
      formData.append('patient[phone]', phone);
      formData.append('patient[address]', address);
      formData.append('patient[gp_details]', gp_details);
      formData.append('patient[is_hayfever]', is_hayfever);
      formData.append('patient[is_asthma]', is_asthma);
      formData.append('patient[is_eczema]', is_eczema);
      formData.append(
        'patient[what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a]',
        what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a
      );
      formData.append(
        'patient[what_allergy_do_you_think_you_might_have_and_why]',
        what_allergy_do_you_think_you_might_have_and_why
      );
      formData.append(
        'patient[what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg]',
        what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg
      );
      formData.append(
        'patient[known_allergies_description]',
        known_allergies_description
      );
      if (known_allergies_description) {
        formData.append('patient[do_you_have_any_known_allergies]', 'true');
      }
    }

    createPatient(formData)
      .then(() => {
        successNotification(
          'Your referral has been sent for triage and we will make contact with you shortly. If you have any queries contact us at info@allergy4all.ie'
        );
        history.push(ROUTE_TO_ROOT);
      })
      .catch((e) => console.error(e));
  };

  const onBookingConfirm = async ({
    stripe,
    data: { price, patientId, sessionId },
  }) => {
    try {
      const data = {
        customer_email: stripe.email,
        customer_token: stripe.id,
        tok_mastercard: stripe.card.id,
        price,
        patient_id: patientId,
        session_id: sessionId,
      };

      await initiatePayment(data);

      successNotification(
        `${
          is_video_call
            ? 'You have successfully booked for the Video Call Appointment.'
            : 'You have successfully booked for the Allergy Test Appointment.'
        }`
      );
      history.push(ROUTE_TO_ROOT);
    } catch (e) {
      errorNotification(e.response.data.error_messages, 5);
    }
  };

  return (
    <StaticPage pageClassName='booking__page'>
      <div className='site__width'>
        <h1 className='page__title'>Book an Appointment</h1>
        <div className='booking__block'>
          <BookingSelect
            setHasReferralLetter={setHasReferralLetter}
            options={APPOINTMENT_TYPE}
          />
          {hasReferralLetter !== null && (
            <div className='booking__form'>
              <div className='booking__form--bg'>
                <BookingForm
                  onFormSubmit={onFormSubmit}
                  hasReferralLetter={hasReferralLetter}
                />
              </div>
            </div>
          )}
          {/* {isConfirmStep && (
            <ConfirmationInfo
              patientData={patientData}
              history={history}
              isVideo={isVideo}
            />
          )} */}
        </div>
      </div>
    </StaticPage>
  );
};

const mapStateToProps = ({ booking: { bookingData, patientData } }) => ({
  bookingData,
  patientData,
});

const mapDispatchToProps = (dispatch) => ({
  createPatient: (data) => dispatch(createPatientAction(data)),
  getPatient: (id) => dispatch(getPatientAction(id)),
  initiatePayment: (data) => dispatch(initiatePaymentAction(data)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message, timeout) =>
    dispatch(errorNotificationAction(message, timeout)),
});

BookingTest.propTypes = {
  createPatient: func.isRequired,
  getPatient: func.isRequired,
  initiatePayment: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  bookingData: objectOf(any).isRequired,
  patientData: objectOf(any).isRequired,
  history: objectOf(any).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingTest);
