import moment from 'moment'

class _Date {
  getDefault() {
    const date = moment()
    const range = { start: date.startOf('month').toDate(), end: date.endOf('month').add(1, 'day').toDate() }
    return range
  }

  nextRange(range) {
    const date = moment(range.start).add(1, 'month')
    const start = date.startOf('month').toDate()
    const end = date.endOf('month').add(1, 'day').toDate()
    return { start, end }
  }

  previousRange(range) {
    const date = moment(range.start).subtract(1, 'month')
    const start = date.startOf('month').toDate()
    const end = date.endOf('month').add(1, 'day').toDate()
    return { start, end }
  }

  extendRange(current_range, new_range) {
    return { start: current_range.start, end: new_range.end }
  }

  // TODO Check for using, replace with moment, and delete
  toTimezone(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  fromTimezoneStr(date) {
    return date.toString().match(/^(.*)\sGMT.*$/)[1]
  }
}

export default _Date = new _Date()
