import React, { useEffect } from 'react'
import { arrayOf, func, bool, object, number } from 'prop-types'
import { connect } from 'react-redux'

import StaticPage from '../../../../components/StaticPage'
import { getBlogsAction } from '../../actions'
import Loader from '../../../../components/Loader'
import MoreArticlesList from './components/ArticlesList'

const PAGE = 1

const MoreArticles = (props) => {
  const { blogs, loading, getBlogs, articleId, errors } = props

  useEffect(
    // eslint-disable-next-line no-shadow
    () => {
      getBlogs(PAGE)
    },
    [getBlogs]
  )

  if (!loading && blogs.length) {
    // Index 3 articles except current. TODO confirm selected logic
    const exceptCurrentPage = blogs.filter((article) => article.id !== articleId)
    const selectedBlogs = exceptCurrentPage.slice(3)

    return (
      <section className='articlesMore__section'>
        <div className='articlesMore__sectionTitle'>More From Allergy4All</div>
        <MoreArticlesList blogs={selectedBlogs} />
      </section>
    )
  }
  if (!loading && errors.length) {
    return <div>{errors[0].message}</div>
  }
  return (
    <StaticPage pageClassName='articleFull__page'>
      <div className='site__width'>
        <Loader />
      </div>
    </StaticPage>
  )
}

MoreArticles.propTypes = {
  loading: bool.isRequired,
  articleId: number.isRequired,
  blogs: arrayOf(object).isRequired,
  getBlogs: func.isRequired,
  errors: arrayOf(object),
}

const mapStateToProps = ({ blog }) => ({
  loading: blog.blogLoading,
  blogs: blog.blogs,
  errors: blog.errors,
})

const mapDispatchToProps = (dispatch) => ({
  getBlogs: async (page) => await dispatch(getBlogsAction(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoreArticles)
