import React from 'react'

const RhinitisAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Allergic Rhinitis</h2>
      <p>
        Allergic Rhinitis is caused by inflammation of the nose due to allergens in the environment. This is commonly known as Hayfever
        and is triggered by pollen travelling in the air. This may present as sneezing, nasal discharge, blocked nose or
        persistent nose itch.
      </p>
    </div>
  )
}

export default RhinitisAllergyInfo
