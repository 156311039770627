import React, { Component } from 'react'
import moment from 'moment'
import './_calendar.css'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import sortBy from 'lodash.sortby'
import Controls from './components/Controls'
import TableCell from './components/TableCell'
import SessionList from './components/views/SessionsList'
import _Date from '../../utils/newDate'
import Loader from '../Loader'
import CreateSessionSlots from './components/views/CreateSessionSlots'
import AvailabilityList from './components/views/AvailabilityList'

const generateUniqueNumber = () => Math.floor(Math.random() * 1000000)

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: _Date.getDefault(),
      dateObject: moment(),
      selectedDay: null,
      selectedDate: null,
      showDates: true,
      calendarRow: null,
      showAvailabilityList: false,
    }
    this.weekDayShort = moment.weekdaysShort()
    this.daysInMonth = this.state.dateObject.daysInMonth()
  }

  componentDidMount() {
    this.onGetMonthSlots()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.age !== this.props.age ||
      prevProps.isVideo !== this.props.isVideo ||
      prevState.range !== this.state.range
    ) {
      this.onGetMonthSlots()
    }
  }

  onGetMonthSlots = () => {
    const { age, isVideo, getMonthSlots } = this.props
    if (!getMonthSlots) return

    const start = moment(this.state.range.start).format('YYYY/MM/DD')
    const end = moment(this.state.range.end).format('YYYY/MM/DD')

    // eslint-disable-next-line react/destructuring-assignment
    getMonthSlots({ range_calendar: `${start} - ${end}`, is_video_call: isVideo, is_adult: age === 'adult' })
      .then(() => {
        this.setState({ selectedDay: null })
      })
      .catch((e) => console.error(e))
  }

  showAvailabilityListHandler = (value = true) => this.setState({ showAvailabilityList: value })

  firstDayOfMonth = () => moment(this.state.dateObject).startOf('month').format('d')

  setMonth = (date) => this.setState({ dateObject: date })

  onMonthSelect = (selecting) => this.setState({ showDates: !selecting })

  onMonthChange = (direction) => {
    if (direction === 'prev') {
      const range = _Date.previousRange(this.state.range)
      this.setState({ dateObject: this.state.dateObject.subtract(1, 'month'), range })
    } else {
      const range = _Date.nextRange(this.state.range)
      this.setState({ dateObject: this.state.dateObject.add(1, 'month'), range })
    }
  }

  fetchSessionsRequest = (selectedDate) => {
    const { age, isVideo } = this.props

    const params = {
      date: selectedDate,
      is_adult: age === 'adult',
      is_video_call: isVideo,
    }

    return this.props.getSessions(params)
  }

  sortByDate = (sessions) => {
    const values = sessions.filter((session) => moment(session.data + session.end_time, 'DD/MM/YYYY HH:mm a').isAfter())
    return sortBy(values, 'start')
  }

  onDayClick = async (e, day, blanksQuantity) => {
    e.stopPropagation()

    if (day === this.state.selectedDay) {
      return this.setState({
        selectedDay: null,
      })
    }

    const calendarRow = Math.ceil((blanksQuantity + day) / 7) // find current row

    const { dateObject } = this.state
    dateObject.set('date', day)

    const selectedDate = dateObject.format('YYYY-MM-DD')

    try {
      await this.fetchSessionsRequest(selectedDate)
    } catch (e) {
      console.error(e)
    }

    return this.setState({
      calendarRow,
      selectedDay: day,
      dateObject,
      selectedDate,
      showAvailabilityList: false,
    })
  }

  onFinishSessionsEditing = async (wasCreated) => {
    const { successNotification, errorNotification } = this.props

    try {
      await this.fetchSessionsRequest(this.state.selectedDate)
      const message = wasCreated
        ? 'You have successfully created new available Appointments on this day.'
        : 'You have successfully updated an available Appointments on this day.'

      successNotification(message)
      this.setState({
        showAvailabilityList: false,
      })
    } catch (e) {
      errorNotification()
      console.error(e)
    }
  }

  calculateAvailableSessions(day) {
    const { range } = this.state
    const { monthSlots } = this.props

    const date = moment(range.start)
      .add(day - 1, 'day')
      .toDate()

    const slots =
      monthSlots &&
      monthSlots.filter((slot) => {
        const slotDate = moment(slot, 'DD/MM/YYYY')

        const TodayOrFuture = moment(slotDate.toDate()).isAfter(moment().subtract(1, 'day'))

        return TodayOrFuture ? moment(slotDate).isSame(date, 'day') : false // unavailable if is past
      })

    return slots.length
  }

  renderDetailsContent = (date, sessions, setBookingData) => {
    const {
      history,
      isVideo,
      age,
      sessionsLoading,
      bookPreliminarily,
      bookSessionByAdmin,
      isAdmin,
      createSessions,
      updateSessions,
      deleteSession,
      successNotification,
      errorNotification,
    } = this.props

    const sortedSessions = !isAdmin ? this.sortByDate(sessions) : sessions
    let content

    if (sessionsLoading) {
      content = <Loader styles={{ margin: '10% auto' }} />
    } else if (this.state.showAvailabilityList) {
      content = (
        <AvailabilityList
          selectedDate={moment(this.state.selectedDate, 'YYYY-MM-DD')}
          sessions={sortedSessions}
          isVideo={isVideo}
          isAdult={age === 'adult'}
          createSessions={createSessions}
          updateSessions={updateSessions}
          deleteSession={deleteSession}
          finishEditing={this.onFinishSessionsEditing}
          successNotification={successNotification}
          errorNotification={errorNotification}
        />
      )
    } else if (sortedSessions.length) {
      content = (
        <SessionList
          selectedDate={date}
          sessions={sortedSessions}
          setBookingData={setBookingData}
          bookPreliminarily={bookPreliminarily}
          bookSessionByAdmin={bookSessionByAdmin}
          history={history}
          isVideo={isVideo}
          age={age}
          isAdmin={isAdmin}
          onShowAvailabilityList={this.showAvailabilityListHandler}
        />
      )
    } else if (!sortedSessions.length) {
      content = isAdmin ? <CreateSessionSlots onClickHandler={this.showAvailabilityListHandler} /> : null
    }

    return (
      <thead key={1} className='calendar-detail'>
        <tr key={2}>
          {content ? (
            <td key={3} colSpan={7}>
              {content}
            </td>
          ) : null}
        </tr>
      </thead>
    )
  }

  render() {
    const { dateObject, selectedDay, selectedDate, calendarRow, showDates } = this.state
    const { sessions, monthLoading, setBookingData, isAdmin } = this.props

    const isPastMonth = moment().isAfter(dateObject, 'month')
    const isCurrentMonth = moment().isSame(dateObject, 'month')

    const weekDayShortNames = this.weekDayShort.map((day) => {
      return (
        <th key={day} className='calendar-short-day'>
          {day}
        </th>
      )
    })

    const blanks = []
    const daysInMonth = []

    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td key={generateUniqueNumber()} className='calendar-day empty' />)
    }

    for (let d = 1; d <= this.daysInMonth; d++) {
      const today = moment().format('D')
      const isPast = isPastMonth || (isCurrentMonth && d < today)
      // eslint-disable-next-line eqeqeq
      const currentDay = d == today // comparison with auto type conversion
      const isActive = d === selectedDay

      daysInMonth.push(
        <TableCell
          isActive={isActive}
          key={d}
          currentDay={currentDay}
          day={d}
          onClickHandler={this.onDayClick}
          blanksQuantity={blanks.length}
          isAdmin={isAdmin}
          isPast={isPast}
          availableSessions={this.calculateAvailableSessions(d)}
        />
      )
    }
    const totalSlots = [...blanks, ...daysInMonth]
    const rows = []
    let cells = []

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells)
      }
    })

    const daysBeforeDetailsBox = []
    const daysAfterDetailsBox = []

    rows.forEach((d, i) => {
      if (i <= calendarRow) {
        daysBeforeDetailsBox.push(<tr key={generateUniqueNumber()}>{d}</tr>)
      } else {
        daysAfterDetailsBox.push(<tr key={generateUniqueNumber()}>{d}</tr>)
      }
    })

    return monthLoading ? (
      <Loader />
    ) : (
      <div className='tail-datetime-calendar'>
        <Controls
          dateObject={dateObject}
          setMonth={this.setMonth}
          changeMonth={this.onMonthChange}
          onMonthSelect={this.onMonthSelect}
        />
        {showDates && (
          <div className='calendar-date'>
            <table className='calendar-days'>
              <thead className='calendar-days'>
                <tr key={generateUniqueNumber()} className='calendar-days-short'>
                  {weekDayShortNames}
                </tr>
              </thead>
              <tbody key={generateUniqueNumber()} className='calendar-days-month'>
                {daysBeforeDetailsBox}
              </tbody>

              {selectedDay && this.renderDetailsContent(selectedDate, sessions, setBookingData)}

              <tbody key={generateUniqueNumber()} className='calendar-days-month'>
                {daysAfterDetailsBox}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

Calendar.propTypes = {
  monthLoading: bool.isRequired,
  sessionsLoading: bool.isRequired,
  isVideo: bool.isRequired,
  isAdmin: bool,
  age: string.isRequired,
  monthSlots: arrayOf(string).isRequired,
  sessions: arrayOf(object).isRequired,
  getMonthSlots: func,
  getSessions: func.isRequired,
  bookPreliminarily: func,
  bookSessionByAdmin: func,
  setBookingData: func.isRequired,
  history: objectOf(any),
  createSessions: func,
  updateSessions: func,
  deleteSession: func,
  successNotification: func,
  errorNotification: func,
}

export default Calendar
