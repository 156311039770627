import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { any, func, bool } from 'prop-types';
import moment from 'moment';
import {
  hasReferralLetterSchema,
  withoutReferralLetterSchema,
} from './validate';
import DatePicker from '../../../../../components/DatePicker';
import history from '../../../../../history';

const phoneRegExp = /^\p{L}+$/u;

// eslint-disable-next-line react/prefer-stateless-function
class BookingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      dob: '',
      birthIsTouched: false,
      email: '',
      phone: '',
      address: '',
      gp_details: '',
      what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a: '',
      what_allergy_do_you_think_you_might_have_and_why: '',
      what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg: '',
      known_allergies_description: '',
      signature: '',
      attachment: null,
      attachmentIsTouched: false,
      is_hayfever: false,
      is_asthma: false,
      is_eczema: false,
    };
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'dob' || name === 'attachment') return;
    this.setState({ [name]: value });
  };

  onCancelHandler = () => {
    this.setState({
      dob: undefined,
      birthIsTouched: false,
      attachment: null,
      attachmentIsTouched: false,
    });
    history.goBack();
  };

  render() {
    const { dob, birthIsTouched, attachment, attachmentIsTouched } = this.state;

    const { onFormSubmit, hasReferralLetter } = this.props;

    const fileUploaderContent = attachment ? (
      <div className='form__file-btn btns btn-b form__file-uploaded'>
        <i className='icons i-attachment' />
        {attachment.name.substr(0, 27)}
        <i
          className='icons i24x24 i-close_red delete-attachment'
          title='Delete'
          onClick={() => {
            this.setState({ attachment: null, attachmentIsTouched: false });
          }}
          onBlur={() => {
            this.setState({ attachmentIsTouched: true });
          }}
        />
      </div>
    ) : (
      <button className='form__file-btn btns btn-b' type='button'>
        <i className='icons i-attachment' />
        Add file
        <input
          className='form__file'
          id='attachment'
          type='file'
          accept='.doc,.docx,.pdf'
          name='attachment'
          onChange={(e) => {
            this.setState({
              attachment: e.target.files[0],
              attachmentIsTouched: true,
            });
          }}
        />
      </button>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={{
          full_name: '',
          email: '',
          phone: '',
          address: '',
          gp_details: '',
          what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a: '',
          what_allergy_do_you_think_you_might_have_and_why: '',
          what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg: '',
          known_allergies_description: '',
          signature: '',
          attachment: null,
          dob: '',
          is_hayfever: false,
          is_asthma: false,
          is_eczema: false,
        }}
        validationSchema={
          hasReferralLetter
            ? hasReferralLetterSchema
            : withoutReferralLetterSchema
        }
        onSubmit={async (
          values,
          {
            setSubmitting,
            resetForm,
            setStatus,
            setErrors,
            setFieldValue,
            setFieldTouched,
          }
        ) => {
          if (!this.state.dob) {
            return;
          }

          if (hasReferralLetter && !this.state.attachment) {
            return;
          }

          try {
            setSubmitting(true);
            await onFormSubmit(this.state);
            resetForm();
            setSubmitting(false);
            setStatus({ success: true });
          } catch (error) {
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}>
        {({
          values,
          isSubmitting,
          errors,
          setFieldValue,
          setFieldTouched,
          setErrors,
        }) => {
          return (
            <Form className='form__default' onChange={this.onChangeHandler}>
              <div className='booking__formBody'>
                <div>
                  <p>
                    Please confirm that you would like to request the following
                    appointment:
                  </p>
                </div>
                <div className='form__row'>
                  <Field
                    name='full_name'
                    className='form__input'
                    placeholder='Patient Full Name'
                  />
                  <ErrorMessage
                    name='full_name'
                    component='div'
                    className='validation-error'
                  />
                </div>

                <div className='form__row calendar'>
                  <DatePicker
                    date={dob}
                    setDate={(value) => {
                      this.setState({ dob: value });
                    }}
                    touch={() => {
                      this.setState({ birthIsTouched: true });
                    }}
                  />
                  {!dob && birthIsTouched ? (
                    <div className='validation-error'>Required</div>
                  ) : null}
                </div>

                {!hasReferralLetter && (
                  <div className='form__row'>
                    <Field
                      name='address'
                      className='form__input'
                      placeholder='Patient Address'
                    />
                    <ErrorMessage
                      name='address'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                )}

                <div className='form__row'>
                  <Field
                    name='email'
                    className='form__input'
                    placeholder='Contact Email'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='validation-error'
                  />
                </div>

                <div className='form__row'>
                  <Field
                    type='string'
                    onKeyDown={(e) => {
                      if (e.key.length === 1 && phoneRegExp.test(e.key)) {
                        // allow control keys, numbers, and symbols
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    name='phone'
                    className='form__input'
                    placeholder='Contact Phone Number'
                  />
                  <ErrorMessage
                    name='phone'
                    component='div'
                    className='validation-error'
                  />
                </div>

                {hasReferralLetter && (
                  <div className='form__row attachment'>
                    <p>Please upload the referral letter (PDF or Word only)</p>
                    {fileUploaderContent}
                    {!attachment && attachmentIsTouched ? (
                      <div className='validation-error'>Required</div>
                    ) : null}
                  </div>
                )}
              </div>

              {!hasReferralLetter && (
                <>
                  <div className='form__row'>
                    <Field
                      name='gp_details'
                      className='form__input'
                      placeholder='GP / Consultant Details'
                    />
                    <ErrorMessage
                      name='gp_details'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <Field
                      name='what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a'
                      className='form__input'
                      placeholder='What concerns or issues do you have that leads you to seek an appointment with Allergy4All?'
                    />
                    <ErrorMessage
                      name='what_concerns_or_issues_do_you_have_that_leads_you_to_seek_an_a'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <Field
                      name='what_allergy_do_you_think_you_might_have_and_why'
                      className='form__input'
                      placeholder='What allergy do you think you might have and why?'
                    />
                    <ErrorMessage
                      name='what_allergy_do_you_think_you_might_have_and_why'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <Field
                      name='what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg'
                      className='form__input'
                      placeholder='What are you hoping to achieve at your consultation with Allergy4All?'
                    />
                    <ErrorMessage
                      name='what_are_you_hoping_to_achieve_at_your_consultation_with_Allerg'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <h5 style={{ marginBottom: '15px' }}>
                      Do you suffer from any of the following?
                    </h5>
                    {['Hayfever', 'Asthma', 'Eczema'].map((item, idx) => (
                      <div className='form__row' key={idx}>
                        <Field
                          type='checkbox'
                          id={`is_${item.toLowerCase()}`}
                          className='form__checkbox'
                          checked={this.state[`is_${item.toLowerCase()}`]}
                          onChange={(e) => {
                            this.setState({
                              [`is_${item.toLowerCase()}`]: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor={`is_${item.toLowerCase()}`}
                          className='form__checkbox--label'>
                          {item}
                        </label>
                      </div>
                    ))}
                    <ErrorMessage
                      name='sufferFrom'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <Field
                      name='known_allergies_description'
                      className='form__input'
                      placeholder='Do you have any known allergies ? If yes, please specify.'
                    />
                    <ErrorMessage
                      name='known_allergies_description'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                </>
              )}

              <div className='form__row'>
                <p>Please sign (or type) your name below to indicate that :</p>
                <p>
                  You acknowledge that an appointment will only be offered if it
                  aligns with the clinic's suitability and expertise.
                </p>
                <p>
                  You understand that any testing on the day of your appointment
                  will be performed at the doctor's discretion, based on what is
                  deemed appropriate for you. Testing may not take place if it
                  is not considered necessary.
                </p>
                <p>
                  You understand that you must stop taking antihistamines for 5
                  days prior to your appointment.
                </p>
                <Field
                  name='signature'
                  className='form__input'
                  placeholder='Signature'
                  autoComplete='off'
                />
                <ErrorMessage
                  name='signature'
                  component='div'
                  className='validation-error'
                />
              </div>

              <div className='form__row--btns'>
                <input
                  className='btns btn-i btn-red_b form__submit'
                  type='submit'
                  name='submit'
                  value={`${
                    isSubmitting ? 'Submitting...' : 'Book Appointment'
                  }`}
                />
                <input
                  className='btns btn-i btn-white_b form__reset'
                  type='reset'
                  name='reset'
                  onClick={this.onCancelHandler}
                  value='Cancel'
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

BookingForm.propTypes = {
  isVideo: bool.isRequired,
  onFormSubmit: func.isRequired,
};

export default BookingForm;
