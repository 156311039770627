import React from 'react'

const ConjunctivitisAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Conjunctivitis</h2>
      <p>
        Conjunctivitis is also known as ocular allergy and occurs when the conjunctiva is irritated by something you
        have an allergy to. The conjunctiva is the fine membrane which coats the eye and inside of the eyelid.
      </p>
    </div>
  )
}

export default ConjunctivitisAllergyInfo
