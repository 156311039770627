import React, { useState } from 'react'
import '../../../../../assets/styles/rc-time-picker.css'
import moment from 'moment'

import './_styles.css'
import { arrayOf, func, object, objectOf, bool, any } from 'prop-types'
import addIcon from '../../../../../assets/img/svg/add.svg'
import AvailabilityListItem from './ListItem'
import { TEST_PRICE, VIDEO_CONSULTATION_PRICE, BREAK_BETWEEN_SESSIONS } from '../../../../../constants/companyData'

const AvailabilityList = ({
  selectedDate,
  sessions,
  isVideo,
  isAdult,
  updateSessions,
  deleteSession,
  finishEditing,
  successNotification,
  errorNotification,
}) => {
  const [sessionsState, setSessionsState] = useState(sessions)
  const [wrongValueIndex, setWrongValueIndex] = useState(null)

  const addNewSession = () => {
    const isToday = selectedDate.isSame(moment(), 'D')

    let startTime

    if (!sessionsState.length) {
      if (isToday) {
        // only future time if today
        const roundedUp = Math.ceil(moment().minute() / 15) * 15 // round to  15 minutes
        startTime = moment().minute(roundedUp).second(0)
      } else {
        startTime = moment(`${selectedDate.format('YYYY-MM-DD')}T08.00`, 'YYYY-MM-DDTHH:mm')
      }
    } else if (sessionsState.length) {
      const lastSessionIsFuture = moment(
        `${selectedDate.format('YYYY-MM-DD')}T${sessionsState[sessionsState.length - 1].start_time}`,
        'YYYY-MM-DDTHH:mm'
      ).isAfter(moment())

      if (lastSessionIsFuture) {
        // next time === prev end time + BREAK_BETWEEN_SESSIONS value
        startTime = moment(
          `${selectedDate.format('YYYY-MM-DD')}T${sessionsState[sessionsState.length - 1].end_time}`,
          'YYYY-MM-DDTHH:mm'
        ).add(BREAK_BETWEEN_SESSIONS, 'minutes')
      } else {
        const roundedUp = Math.ceil(moment().minute() / 15) * 15 // round to  15 minutes
        startTime = moment().minute(roundedUp).second(0)
      }
    }

    const endTime = moment(startTime, 'hh:mm').add(30, 'minutes')

    if (!startTime.isSame(selectedDate, 'D')) return // selected day only

    const initialSessionData = {
      data: selectedDate.format('DD/MM/YYYY'),
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      is_adult: isAdult,
      is_video_call: isVideo,
      price: isVideo ? VIDEO_CONSULTATION_PRICE : TEST_PRICE,
      status: 'available',
    }

    setSessionsState([...sessionsState, initialSessionData])
  }

  const onDeleteSessionHandler = (session) => {
    if (session.id) {
      session.status === 'available'
        ? deleteSession(session.id)
            .then(() => successNotification('You have successfully deleted selected available appointment.'))
            .catch((e) => {
              errorNotification()
              console.error(e)
            })
        : errorNotification('You can not delete a booked appointment')
    }

    const withoutThatSession = sessionsState.filter(
      (s) => s.start_time !== session.start_time && s.end_time !== session.end_time
    )
    return setSessionsState(withoutThatSession)
  }

  const formatDataForRequest = (data) => {
    const validData = []
    data.forEach((session) => {
      // formatting data for API requirements
      const validDate = session.data.split('/').reverse().join('-')

      const startDate = moment(`${validDate}T${session.start_time}`).format('YYYY-MM-DDTHH:mm')
      const endDate = moment(`${validDate}T${session.end_time}`).format('YYYY-MM-DDTHH:mm')

      validData.push({
        start_date: startDate,
        end_date: endDate,
        is_adult: session.is_adult,
        is_video_call: session.is_video_call,
        price: session.price,
        id: session.id,
        location: session.location,
      })
    })
    return validData
  }

  const validate = (timePeriod) => {
    return sessionsState.find((session) => {
      const target = moment(session[timePeriod], 'HH:mm')

      return sessionsState.find((ses) => {
        const start = moment(ses.start_time, 'HH:mm')
        const end = moment(ses.end_time, 'HH:mm')

        return target.isAfter(start, 'minutes') && target.isBefore(end, 'minutes')
      })
    })
  }

  const onSaveHandler = () => {
    // checking matching range
    const incorrectValue = validate('start_time') || validate('end_time')

    if (incorrectValue) {
      return setWrongValueIndex(() => {
        return sessionsState.findIndex(
          (session) => session.start_time === incorrectValue.start_time && session.end_time === incorrectValue.end_time
        )
      })
    }

    const sessions_create = []
    const sessions_update = []

    const data = formatDataForRequest(sessionsState)

    data.forEach((session) => (session.id ? sessions_update.push(session) : sessions_create.push(session)))

    updateSessions({ sessions_create, sessions_update })
      .then(() => finishEditing(sessions_create.length))
      .catch((e) => console.error(e))
  }

  return (
    <div className='availableList-wrapper'>
      <div className='availableList-container'>
        <div className='availableList-title'>
          <p>Edit the Appointments on this day</p>
          <button className='btns btn-i btn-edit' type='button' onClick={onSaveHandler}>
            Save
          </button>
        </div>

        <ul className='availableList-list'>
          {sessionsState.map((session, index) => (
            <AvailabilityListItem
              key={session.start_time + index}
              isIncorrect={index === wrongValueIndex}
              session={session}
              selectedDate={selectedDate}
              onDeleteSession={onDeleteSessionHandler}
              isAdult={isAdult}
              index={index}
            />
          ))}
        </ul>
        <button type='button' className='add-available-wrapper' onClick={addNewSession}>
          <img className='add-available-icon pointer' src={addIcon} alt='Create session' />
          <div className='add-available-button'>Add another session slot</div>
        </button>
      </div>
    </div>
  )
}

AvailabilityList.propTypes = {
  selectedDate: objectOf(any).isRequired,
  sessions: arrayOf(object).isRequired,
  isVideo: bool.isRequired,
  isAdult: bool.isRequired,
  updateSessions: func.isRequired,
  deleteSession: func.isRequired,
  finishEditing: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
}

export default AvailabilityList
