import React from 'react'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'

const MenuItem = ({ title, path }) => {
  return (
    <li key={title} className='header__main--item'>
      <NavLink to={path} className='btns btn-i'>
        {title}
      </NavLink>
    </li>
  )
}

MenuItem.propTypes = {
  title: string.isRequired,
  path: string.isRequired,
}

export default MenuItem
