import api from '../../../utils/api';

const route = '/admin/team_members';

export const getTeamApi = (params) => api.get(route, { params });
export const createTeamMemberApi = (data) => api.post(route, data);
export const updateTeamMemberApi = (id, data) =>
  api.put(`${route}/${id}`, data);
export const deleteTeamMemberApi = (id) => api.delete(`${route}/${id}`);
export const getTeamMemberByIdApi = (id) => api.get(`${route}/${id}`);
