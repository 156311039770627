/* eslint-disable no-underscore-dangle,no-param-reassign */
import React, { useState } from 'react'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { any, objectOf, bool, func, number } from 'prop-types'
import Select from 'react-select'
import deleteIcon from '../../../../../assets/img/svg/delete.svg'
import ConfirmationModal from '../../../../ConfirmationModal'
import { CLINICS_ADDRESSES } from '../../../../../constants/companyData'

const AvailabilityListItem = ({session, isIncorrect, onDeleteSession, selectedDate, isAdult, index}) => {
  const [address, setAddress] = useState(
    isAdult
      ? CLINICS_ADDRESSES.find((addr) => addr.value === 'adult_1')
      : CLINICS_ADDRESSES.find((addr) => addr.value === 'child'),
  )
  const [showModal, setShowModal] = useState(false)
  const [startTime, setStartTime] = useState(
    moment(`${selectedDate.format('YYYY-MM-DD')}T${session.start_time}`, 'YYYY-MM-DDTHH:mm'),
  )
  const [endTime, setEndTime] = useState(
    moment(`${selectedDate.format('YYYY-MM-DD')}T${session.end_time}`, 'YYYY-MM-DDTHH:mm'),
  )

  session.start_time = startTime.format('HH:mm')
  session.end_time = endTime.format('HH:mm')

  const disabled = startTime.isSameOrBefore(moment()) || session.status !== 'available'

  const sessionDeleteHandler = () => (session.id ? setShowModal(true) : onDeleteSession(session))

  session.location = address && address.label
  return (
    <>
      {showModal ? (
        <ConfirmationModal
          handler={() => onDeleteSession(session)}
          hideModalHandler={() => setShowModal(false)}
          title='Delete available booking time ?'
          message='Users cannot book an appointment at this time.'
          mainButtonContent='Delete'
        />
      ) : null}
      <li>
        <div className={`availableList-item ${disabled ? 'disabled-availableList-item' : ''}`}>
          <div className='availableList-item-time disabled'>
            <p>Start</p>
            <TimePicker
              use12Hours
              format='hh:mm A'
              onChange={setStartTime}
              value={startTime}
              minuteStep={5}
              showSecond={false}
              disabled={disabled}
              className={`form--input timepicker-here pointer ${
                disabled ? 'disabled-availableList-item disabled' : ''
              }`}
              clearIcon={<i style={{display: 'none'}}/>}
            />
          </div>
          <div className='availableList-item-time'>
            <p>End</p>
            <TimePicker
              use12Hours
              format='hh:mm A'
              onChange={setEndTime}
              value={endTime}
              minuteStep={5}
              showSecond={false}
              disabled={disabled}
              className={`form--input timepicker-here pointer ${
                disabled ? 'disabled-availableList-item disabled' : ''
              }`}
              clearIcon={<i style={{display: 'none'}}/>}
            />
          </div>
          <div className={`availability-custom-select select-${index}`}>
            <Select
              value={address}
              options={CLINICS_ADDRESSES}
              onChange={setAddress}
              placeholder='Choose address'
              isDisabled={disabled}
              maxMenuHeight={400}
              onMenuOpen={() => document.querySelector(`.select-${index}`).style.zIndex = "10"}
              onMenuClose={() => document.querySelector(`.select-${index}`).style.zIndex = "1"}
            />
          </div>
          <button
            type='button'
            disabled={disabled}
            onClick={sessionDeleteHandler}
            className={`calendar-button ${disabled ? 'disabled-availableList-item' : ''}`}>
            <img src={deleteIcon} className={`img-svg delete-svg ${disabled ? 'disabled' : ''}`} alt='Delete'/>
          </button>
        </div>
        {startTime.isSameOrAfter(endTime) ? (
          <div className='validation-error availability-error'>Start time can`t be after end time</div>
        ) : null}
        {isIncorrect ? <div className='validation-error availability-error'>That time range is occupied</div> : null}
      </li>
    </>
  )
}

AvailabilityListItem.propTypes = {
  session: objectOf(any).isRequired,
  isIncorrect: bool.isRequired,
  isAdult: bool.isRequired,
  onDeleteSession: func.isRequired,
  selectedDate: objectOf(any).isRequired,
  index: number.isRequired,
}

export default AvailabilityListItem
