/* eslint-disable import/prefer-default-export */

// Teammates
import davidCoghlanImg from '../assets/img/team-1.jpg';
import davidCoghlanImg_2x from '../assets/img/team-1@2x.jpg';
import davidCoghlanImg_3x from '../assets/img/team-1@3x.jpg';

import billCoghlanImg from '../assets/img/team-2.jpg';
import billCoghlanImg_2x from '../assets/img/team-2@2x.jpg';
import billCoghlanImg_3x from '../assets/img/team-2@3x.jpg';

import claireGrayImg from '../assets/img/team-3.jpg';
import claireGrayImg_2x from '../assets/img/team-3@2x.jpg';
import claireGrayImg_3x from '../assets/img/team-3@3x.jpg';

import lizCoghlanImg from '../assets/img/team-5.jpg';
import lizCoghlanImg_2x from '../assets/img/team-5@2x.jpg';
import lizCoghlanImg_3x from '../assets/img/team-5@3x.jpg';

import fionaBradyImg from '../assets/img/team-6.jpg';
import fionaBradyImg_2x from '../assets/img/team-6@2x.jpg';
import fionaBradyImg_3x from '../assets/img/team-6@3x.jpg';

// Advisors
import advisor1Img from '../assets/img/advisor-1.jpg';
import advisor1Img_2x from '../assets/img/advisor-1@2x.jpg';
import advisor1Img_3x from '../assets/img/advisor-1@3x.jpg';

import advisor2Img from '../assets/img/advisor-2.jpg';
import advisor2Img_2x from '../assets/img/advisor-2@2x.jpg';
import advisor2Img_3x from '../assets/img/advisor-2@3x.jpg';

// Allergy resources
import resource1 from '../assets/img/resource-1.jpg';
import resource1_2x from '../assets/img/resource-1@2x.jpg';
import resource1_3x from '../assets/img/resource-1@3x.jpg';

import resource2 from '../assets/img/resource-2.jpg';
import resource2_2x from '../assets/img/resource-2@2x.jpg';
import resource2_3x from '../assets/img/resource-2@3x.jpg';

import resource3 from '../assets/img/resource-3.jpg';
import resource3_2x from '../assets/img/resource-3@2x.jpg';
import resource3_3x from '../assets/img/resource-3@3x.jpg';

import resource4 from '../assets/img/resource-4.jpg';
import resource4_2x from '../assets/img/resource-4@2x.jpg';
import resource4_3x from '../assets/img/resource-4@3x.jpg';

import resource5 from '../assets/img/resource-5.jpg';
import resource5_2x from '../assets/img/resource-5@2x.jpg';
import resource5_3x from '../assets/img/resource-5@3x.jpg';

import resource6 from '../assets/img/resource-6.jpg';
import resource6_2x from '../assets/img/resource-6@2x.jpg';
import resource6_3x from '../assets/img/resource-6@3x.jpg';

import resource7 from '../assets/img/resource-7.jpg';
import resource7_2x from '../assets/img/resource-7@2x.jpg';
import resource7_3x from '../assets/img/resource-7@3x.jpg';

import resource8 from '../assets/img/resource-8.jpg';
import resource8_2x from '../assets/img/resource-8@2x.jpg';
import resource8_3x from '../assets/img/resource-8@3x.jpg';

import resource9 from '../assets/img/resource-9.jpg';
import resource9_2x from '../assets/img/resource-9@2x.jpg';
import resource9_3x from '../assets/img/resource-9@3x.jpg';

// Allergy types
import { ReactComponent as Oral } from '../assets/img/svg/allergyTypes/icon-allergy_oral.svg';
import { ReactComponent as Oral_active } from '../assets/img/svg/allergyTypes/icon-allergy_oral_active.svg';
import { ReactComponent as Rhinitis } from '../assets/img/svg/allergyTypes/icon-allergy_rhinitis.svg';
import { ReactComponent as Rhinitis_active } from '../assets/img/svg/allergyTypes/icon-allergy_rhinitis_active.svg';
import { ReactComponent as Grass } from '../assets/img/svg/allergyTypes/icon-allergy_grass.svg';
import { ReactComponent as Grass_active } from '../assets/img/svg/allergyTypes/icon-allergy_grass_active.svg';
import { ReactComponent as Conjunctivitis } from '../assets/img/svg/allergyTypes/icon-allergy_conjunctivitis.svg';
import { ReactComponent as Conjunctivitis_active } from '../assets/img/svg/allergyTypes/icon-allergy_conjunctivitis_active.svg';
import { ReactComponent as Food } from '../assets/img/svg/allergyTypes/icon-allergy_food.svg';
import { ReactComponent as Food_active } from '../assets/img/svg/allergyTypes/icon-allergy_food_active.svg';
import { ReactComponent as Asthma } from '../assets/img/svg/allergyTypes/icon-allergy_asthma.svg';
import { ReactComponent as Asthma_active } from '../assets/img/svg/allergyTypes/icon-allergy_asthma_active.svg';
import { ReactComponent as Dust } from '../assets/img/svg/allergyTypes/icon-allergy_dust.svg';
import { ReactComponent as Dust_active } from '../assets/img/svg/allergyTypes/icon-allergy_dust_active.svg';
import { ROUTE_TO_ALLERGY_TEST } from './routes';

export const TEST_PRICE = 180;
export const VIDEO_CONSULTATION_PRICE = 85;
export const BREAK_BETWEEN_SESSIONS = 10;

export const OUR_TEAM = [
  {
    id: 'd_coghlan',
    title: 'Prof David Coghlan',
    position: 'Medical Director',
    role: 'team_member',
    img: {
      x1: davidCoghlanImg,
      x2: davidCoghlanImg_2x,
      x3: davidCoghlanImg_3x,
    },
    description: [
      'Our Medical Director is Prof David Coghlan, Consultant Paediatrician appointed to the National Children’s Hospital, Tallaght and Our Lady’s Children’s Hospital, Crumlin.',
      'He runs independent clinics at the Beacon Hospital in Dublin 18, the NCH in Dublin 24 and the Blackrock Clinic in South County Dublin.',
      'His hospital practice centres on children with acute medical illness. His special interests include asthma, allergy and feeding failure in early childhood.',
    ],
  },
  {
    id: 'b_coghlan',
    title: 'Mr Bill Coghlan',
    position: 'Director of Allergy4All',
    role: 'team_member',
    img: {
      x1: billCoghlanImg,
      x2: billCoghlanImg_2x,
      x3: billCoghlanImg_3x,
    },
    description: [
      'Bill is a Director of Allergy4All and oversees the firm’s marketing and sales functions as well as the day-to-day operations of the business.',
      "Bill holds a Bachelor's Degree in Business Studies and a Diploma in Pharmaceutical Business Management from UCD Michael Smurfit Graduate Business School.",
      'He worked in various areas of the pharmaceutical industry over an 8 year period following 3 years in financial services. This experience has laid the foundations and given him the necessary skills to bring essential, clinically led, validated diagnostic allergy testing to patients in Ireland and has provided a clear, efficient referral pathway for GPs, Consultants and healthcare professionals throughout Ireland.',
    ],
  },
  {
    id: 'c_gray',
    title: 'Ms Claire Gray (Cullinane)',
    position: 'Clinical Nurse Specialist',
    role: 'team_member',
    img: {
      x1: claireGrayImg,
      x2: claireGrayImg_2x,
      x3: claireGrayImg_3x,
    },
    description: [
      'Our Clinical Nurse Specialist is Ms. Claire Cullinane. Claire has worked with us in Allergy4All since 2015.',
      'Previous to this, Claire worked as a Clinical Nurse Specialist in paediatric allergy for six years in the allergy service in Cork University Hospital. ',
      'She has been a co-author on a number of research papers and has published two papers on food allergy. Claire is also a Registered Nurse Prescriber having qualified from the Royal College of Surgeons in 2019.',
    ],
  },
  {
    id: 'l_coghlan',
    title: 'Ms Liz Coghlan',
    position: 'Allergy Nurse',
    role: 'team_member',
    img: {
      x1: lizCoghlanImg,
      x2: lizCoghlanImg_2x,
      x3: lizCoghlanImg_3x,
    },
    description: [
      'Liz has over 12 years of healthcare experience in the areas of general nursing, midwifery and clinical research.',
      'Prior to joining Allergy4All she worked as a Senior Clinical Research Associate with Cancer Trials Ireland.',
      'Liz completed her Bachelor of Nursing Degree in Dublin City University and her Master’s Degree in Mediation and Conflict Resolution in University College Dublin.  Her areas of interest include allergy, immunology, infectious diseases and oncology.',
    ],
  },
  {
    id: 'f_brady',
    title: 'Ms Fiona Brady',
    position: 'Allergy Nurse',
    role: 'team_member',
    img: {
      x1: fionaBradyImg,
      x2: fionaBradyImg_2x,
      x3: fionaBradyImg_3x,
    },
    description: [
      'Fiona has a special interest in allergy since joining the Allergy4All team in 2013.',
      'Fiona has a Bachelor of Science form NUI Maynooth and a MSc. in Biomedical Diagnostics from Dublin City University. She is currently studying Medicine in University College Cork.',
    ],
  },
];

export const OUR_ADVISORS = [
  {
    id: 'j_hourihane',
    title: 'Prof Jonathan Hourihane',
    position: 'Paediatric Allergist',
    role: 'advisor',
    img: {
      x1: advisor1Img,
      x2: advisor1Img_2x,
      x3: advisor1Img_3x,
    },
    description: [
      "Prof Hourihane is one of the world's leading paediatric allergists and is at the forefront of recent and ongoing studies of oral and epicutaneous immunotherapy for peanut allergy. He graduated from Trinity College Dublin in 1987 and undertook his higher training in Southampton and London, UK.",
      'He has taken up a new post as Professor of Paediatrics in RCSI and Consultant Paediatrician in Children’s Hospital Ireland at Temple Street.',
      'He has moved from University College Cork, where he had held a similar chair since 2005. He is President of the Irish Association of Allergy and Immunology and is a board member of the National Childrens’ Research Centre, Dublin.',
    ],
  },
  // {
  //   id: 'a_deegan',
  //   title: 'Dr Alex Deegan',
  //   position: 'Consultant Respiratory and General Physician',
  //   role: 'advisor',
  //   img: {
  //     x1: advisor2Img,
  //     x2: advisor2Img_2x,
  //     x3: advisor2Img_3x,
  //   },
  //   description: [
  //     'Dr. Deegan is the Clinical Director of the Respiratory and Sleep Laboratory in Blackrock Clinic. Dr. Deegan graduated in 1997 from University of Hamburg, Germany. He went on to attain the  Specialist Certificate in Internal Medicine in 2004 and in Respiratory Medicine in 2007. In July 2008 he moved to Dublin to work in a busy clinical position at St Vincent’s University Hospital, where he also participated in research with Professor Walter Mc Nicholas.',
  //     'Dr Deegan has been on the Irish Register of Medical Specialists in Respiratory Medicine and General Internal Medicine since 2008 and in the same year he became the first physician in Ireland to be awarded the European Diploma in Adult Respiratory Medicine by the first European Respiratory Society / HERMES.',
  //     'Since 2010 Dr Deegan has worked as a Consultant Respiratory and General Physician. Dr Deegan has been closely involved in setting up a state of the art Sleep Laboratory at Blackrock Clinic, which has been fully approved by VHI. He is performing day case bronchoscopy and EBUS (endobronchial ultrasound) procedures in the Day Unit. He is currently Chairman of the Drugs, Therapeutics and Medication Safety Committee and a member of the Infection Prevention and Control Committee at Blackrock Clinic.',
  //   ],
  // },
];

export const ALLERGY_TYPES = [
  {
    id: 'oral',
    svg: Oral,
    activeSvg: Oral_active,
    title: 'Oral allergy syndrome',
  },
  {
    id: 'rhinitis',
    svg: Rhinitis,
    activeSvg: Rhinitis_active,
    title: 'Allergic Rhinitis',
  },
  {
    id: 'grass',
    svg: Grass,
    activeSvg: Grass_active,
    title: 'Grass',
  },
  {
    id: 'conjunctivitis',
    svg: Conjunctivitis,
    activeSvg: Conjunctivitis_active,
    title: 'Conjunctivitis',
  },
  {
    id: 'food',
    svg: Food,
    activeSvg: Food_active,
    title: 'Food',
  },
  {
    id: 'asthma',
    svg: Asthma,
    activeSvg: Asthma_active,
    title: 'Asthma',
  },
  {
    id: 'dust',
    svg: Dust,
    activeSvg: Dust_active,
    title: 'House dust',
  },
];

export const ALLERGY_RESOURCES = [
  {
    id: '1',
    title: 'Irish Skin Foundation',
    info: 'Information Booklets & Resources',
    link: 'https://irishskin.ie/',
    img: {
      x1: resource1,
      x2: resource1_2x,
      x3: resource1_3x,
    },
  },
  {
    id: '2',
    title: 'IFAN',
    info: 'Irish Food Allergy Network',
    link: 'http://ifan.ie/',
    img: {
      x1: resource2,
      x2: resource2_2x,
      x3: resource2_3x,
    },
  },
  {
    id: '3',
    title: 'AllergyUK',
    info: 'The Milk Allergy in Primary Care (MAP) Guideline 2019',
    link: 'https://www.allergyuk.org/',
    img: {
      x1: resource3,
      x2: resource3_2x,
      x3: resource3_3x,
    },
  },
  {
    id: '4',
    title: 'IFAN',
    info: 'IFAN’s “Egg ladder” has been updated',
    link: 'http://ifan.ie/ifans-egg-ladder-has-been-updated/',
    img: {
      x1: resource4,
      x2: resource4_2x,
      x3: resource4_3x,
    },
  },
  {
    id: '5',
    title: 'EAACI',
    info:
      'Anaphylaxis: Guidelines from the European Academy of Allergy and Clinical Immunology',
    link: 'https://www.eaaci.org/',
    img: {
      x1: resource5,
      x2: resource5_2x,
      x3: resource5_3x,
    },
  },
  {
    id: '6',
    title: 'BSACI',
    info: 'British Society for Allergy & Clinical Immunology (BSACI)',
    link: 'https://www.bsaci.org/',
    img: {
      x1: resource6,
      x2: resource6_2x,
      x3: resource6_3x,
    },
  },
  {
    id: '7',
    title: 'Spare Pens in Schools',
    info: 'Anaphylaxis Information for Schools',
    link: 'https://www.sparepensinschools.uk/',
    img: {
      x1: resource7,
      x2: resource7_2x,
      x3: resource7_3x,
    },
  },
  {
    id: '8',
    title: 'AC',
    info: 'Anaphylaxis Campaign',
    link: 'https://www.anaphylaxis.org.uk/',
    img: {
      x1: resource8,
      x2: resource8_2x,
      x3: resource8_3x,
    },
  },
  {
    id: '9',
    title: 'Itchy Sneezy Wheezy',
    info: 'Everything you need to know about allergies',
    link: 'http://www.allergyacademy.org/patients/itchy-sneezy-wheezy/',
    img: {
      x1: resource9,
      x2: resource9_2x,
      x3: resource9_3x,
    },
  },
];

export const FAQS = [
  {
    id: 1,
    title: 'What type of testing do you provide?',
    content: 'We use the Skin Prick Test method of testing.',
  },
  {
    id: 2,
    title: 'How does the Skin Prick Test work?',
    content:
      'The procedure is conducted by placing a drop of allergen solution on the skin (usually the forearm with your palm facing the ceiling). The skin is broken by lightly pricking the surface of allergen droplet using a small lancet. The immune system then begins to interact with the allergen and if you are allergic to the allergen you will produce a small itchy hive at the site of the pin prick. This is called a wheal. Wheals are visible to the naked eye and can be measured with a ruler. If you are not allergic to the allergen, nothing happens. The test is read after 15 to 20 minutes.',
  },
  {
    id: 3,
    title: 'What do you test for?',
    content:
      'We can test for food and airborne allergens. Examples of food allergens are fruit, vegetables, shellfish, nuts, egg, cow’s milk, soy and wheat. Examples of our airborne allergens are house dust mite, pollens, moulds and animal dander.',
  },
  {
    id: 4,
    title: 'Do you test for penicillin allergy?',
    content:
      'No we don’t test for penicillin allergy. We only test for food and airborne allergens as above.',
  },
  {
    id: 5,
    title: 'Can you test for an allergy to chemicals/metals/rubber?',
    content:
      'These are diagnosed by a patch test. We do not provide patch testing in Allergy4All at present.',
  },
  {
    id: 6,
    title: 'How accurate is the Skin Prick Test?',
    content:
      'When used correctly the skin prick test has good sensitivity and specificity for the presence of allergen-specific IgE and is in some cases more sensitive than blood testing for specific IgE in serum. A comprehensive history (including a family history of atopy) and a careful physical examination form the foundation of a secure diagnosis. Laboratory investigations are not diagnostic but can support a diagnosis made on clinical grounds.',
  },
  {
    id: 7,
    title: 'Is the Skin Prick Test painful?',
    content:
      'There is little discomfort and the risk of systemic reactions is minimal if testing is performed in a standardised fashion.',
  },
  {
    id: 8,
    title: 'When will I receive the results of the allergy test?',
    content:
      'You will receive your results on the day of the test. A copy can also be sent to your referring GP/Consultant if required.',
  },
  {
    id: 9,
    title: 'Do I need to stop any medication before the test?',
    content:
      'All antihistamine medication must be discontinued 5 days prior to the test.',
  },
  {
    id: 10,
    title: 'Do I need to fast before the test?',
    content: 'No, you don’t need to fast before the test.',
  },
  {
    id: 11,
    title: 'How long does the whole appointment take?',
    content: 'Approximately 45 minutes',
  },
  {
    id: 12,
    title: 'How much is the allergy test?',
    content: 'The cost is €180.',
  },
  {
    id: 13,
    title: 'What age groups do you provide allergy testing for?',
    content:
      'We provide testing for both adults and children. We can test babies from 6 months and older.',
  },
  {
    id: 14,
    title: 'Is the environment you are located in for allergy testing safe?',
    content:
      'We will NEVER conduct an Allergy4All testing clinic without a Consultant Doctor on site for the entire duration of the clinic. All our clinics our run within a Hospital setting.',
  },
  {
    id: 15,
    title: 'Where are you located?',
    content:
      'We run our Paediatric clinic in Suite 36, Beacon for Kids, Beacon Hospital, Sandyford, Dublin 18 and in Suite 12, Beacon Consultants Suites, Beacon Hospital, Sandyford, Dublin 18.',
  },
  {
    id: 16,
    title: 'How do I make an appointment with you for allergy testing?',
    content: '',
    asHtml:
      "<p>You can send appointment requests/referrals via email to <a class='original-link-decor' href='mailto: info@allergy4all.ie'>info@allergy4all.ie</a>, you can book by sending a request through the contact form on the ‘contact us’ page of the website, you can book by phoning <a class='original-link-decor' href='tel: 086-073-6297'>086-073-6297</a> or you can book through the booking engine on our website by clicking <a class='original-link-decor' href='sessions_availability/booking_test'>Book an Appointment</a>.</p>",
  },
];

export const APPOINTMENT_TYPES = [
  {
    value: 'adult',
    label: 'Adult appointment (17yrs+)',
    note:
      'Adult appointments take place in Suite 23, Blackrock Clinic on Monday’s and in Suite 12, Beacon Consultants Suites, Beacon Hospital, Sandyford on Tuesday’s and Friday’s.',
  },
  {
    value: 'child',
    label: 'Child appointment (6 months of age - 16 yrs).',
    note: `Appointments for children take place on Tuesdays and Wednesdays in Suite 36, Beacon for
          Kids, Beacon Hospital, Sandyford and on Fridays in Suite 12, Beacon Consultants Suites, Beacon Hospital,
          Sandyford.`,
  },
];

// export const APPOINTMENT_TYPES_VIDEO = [
//   {
//     value: 'adult',
//     label: 'Adult appointment - (16yrs +) with Allergy Nurse Specialist',
//     note: null,
//   },
//   {
//     value: 'child',
//     label: 'Child appointment - (16yrs or Under) with Allergy Nurse Specialist',
//     note: 'All child appointments  must be accompanied by an adult.',
//   },
// ];

export const APPOINTMENT_TYPE = [
  {
    value: true,
    label: 'I have a referral letter ',
  },
  {
    value: false,
    label: 'I do not have a referral letter',
  },
];

export const APPOINTMENT_TYPES_ADMIN = [
  {
    value: 'adult',
    label: 'Adult appointment',
  },
  {
    value: 'child',
    label: 'Child appointment',
  },
];

export const SESSION_TYPES = [
  {
    value: 'test',
    label: 'Allergy test',
  },
  {
    value: 'video',
    label: 'Video consultation',
  },
];

export const CLINICS_ADDRESSES = [
  {
    value: 'adult_1',
    label:
      'Suite 12, Beacon Consultants Suites, Beacon Hospital, Sandyford, Dublin 18',
  },
  {
    value: 'adult_2',
    label: 'Suite 23, Blackrock Clinic, Rock Road, Blackrock, Co Dublin',
  },
  {
    value: 'child',
    label: 'Suite 36, Beacon for Kids, Beacon Hospital, Sandyford, Dublin 18',
  },
];

export const COORDINATES = {
  BEACON_HOSPITAL: { lat: 53.276995, lng: -6.219493 },
  BLACKROCK_CLINIC: { lat: 53.305152, lng: -6.188182 },
  CENTER_OF_MAP: { lat: 53.291092, lng: -6.209807 },
};

export const GOOGLE_MAP_URL =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBBIcXIrBHHJi2XpduCAlUgojXKOMskF0w&v=3.exp&libraries=geometry,drawing,places';
