import typeToReducer from 'type-to-reducer';
import { GET_PATIENT_BY_ID, GET_PATIENTS } from './constants';

export const initialState = {
  patients: [],
  patientsPages: 0,
  patientsCount: 0,
  errors: [],
  patientsLoading: false,
  patient: {},
};

export default typeToReducer(
  {
    [GET_PATIENTS]: {
      START: (state) => {
        return {
          ...state,
          patientsLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          patients: action.payload.data && action.payload.data.patients,
          patientsPages: action.payload.data && action.payload.data.pages,
          patientsCount: action.payload.data && action.payload.data.count,
          patientsLoading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          patientsLoading: false,
        };
      },
    },
    [GET_PATIENT_BY_ID]: {
      START: (state) => {
        return {
          ...state,
          patientsLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          patient: action.payload.data.patient,
          patientsLoading: false,
        };
      },
    },
  },
  initialState
);
