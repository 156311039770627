import typeToReducer from 'type-to-reducer';
import {
  CLEAR_TEAM_MEMBER_BY_ID_LANDING,
  GET_TEAM_LANDING,
  GET_TEAM_MEMBER_BY_ID_LANDING,
} from './constants';

export const initialState = {
  loading: false,
  team: [],
  teamMember: null,
  pages: 0,
  count: 0,
  errors: [],
};

export default typeToReducer(
  {
    [GET_TEAM_LANDING]: {
      START: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          team: action.payload.data && action.payload.data.team_members,
          pages: action.payload.data && action.payload.data.pages,
          count: action.payload.data && action.payload.data.count,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [GET_TEAM_MEMBER_BY_ID_LANDING]: {
      START: (state) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          teamMember: action.payload.data && action.payload.data.team_member,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [CLEAR_TEAM_MEMBER_BY_ID_LANDING]: (state) => {
      return {
        ...state,
        teamMember: null,
      };
    },
  },
  initialState
);
