import { createAction } from 'redux-actions'

import {
  GET_MONTH_SLOTS,
  GET_SESSIONS,
  SET_BOOKING_DATA,
  BOOK_PRELIMINARILY,
  CREATE_PATIENT,
  GET_PATIENT,
  INITIATE_PAYMENT,
} from './constants'
import {
  getMonthSlotsApi,
  getSessionsApi,
  bookPreliminarilyApi,
  createPatientApi,
  getPatientApi,
  initiatePaymentApi,
} from './api'

export const getSessionsAction = createAction(GET_SESSIONS, async (data) => await getSessionsApi(data))
export const getMonthSlotsAction = createAction(GET_MONTH_SLOTS, async (data) => await getMonthSlotsApi(data))
export const createPatientAction = createAction(CREATE_PATIENT, async (data) => await createPatientApi(data))
export const getPatientAction = createAction(GET_PATIENT, async (id) => await getPatientApi(id))
export const bookPreliminarilyAction = createAction(BOOK_PRELIMINARILY, async (id) => await bookPreliminarilyApi(id))
export const initiatePaymentAction = createAction(INITIATE_PAYMENT, async (data) => await initiatePaymentApi(data))
export const setBookingDataAction = createAction(SET_BOOKING_DATA)
