import React from 'react';
import { any, bool, objectOf } from 'prop-types';
import Icon from '../../../Icons/Edit';
import history from '../../../../../history';
import {
  routeToAppointmentAdmin,
  routeToArticleAdmin,
  routeToBookingAdmin,
  routeToEditFAQByIdAdmin,
  routeToEditTeamMemberAdmin,
} from '../../../../../constants/routes';

const EditRowButton = ({
  appointment,
  isBlog,
  isTeam,
  isFaq,
  isAppointments,
}) => {
  const onClickHandler = () => {
    let to;

    if (isBlog) {
      to = routeToArticleAdmin('edit');
    } else if (isTeam) {
      to = routeToEditTeamMemberAdmin(appointment.id);
    } else if (isFaq) {
      to = routeToEditFAQByIdAdmin(appointment.id);
    } else {
      to = routeToAppointmentAdmin(appointment.id);
    }

    history.push(to, appointment);
  };

  return (
    <button
      type='button'
      className='btns delete_btn'
      title='Edit'
      onClick={onClickHandler}>
      <Icon />
    </button>
  );
};

EditRowButton.propTypes = {
  isBlog: bool,
  appointment: objectOf(any).isRequired,
};

export default EditRowButton;
