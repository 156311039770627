import React from 'react';
import { any, bool, func } from 'prop-types';

const AddUserButton = ({ children, onClickHandler, isBlog, isTeam, isFaq }) => {
  const getTitleByType = () => {
    if (isBlog) return 'Add article';
    if (isTeam) return 'Add team member';
    if (isFaq) return 'Add FAQ';
    return 'Add user';
  };

  const title = getTitleByType();

  return (
    <button
      type='button'
      className='btns btn-b adduser_btn'
      title={title}
      onClick={onClickHandler}>
      {children}
    </button>
  );
};

AddUserButton.propTypes = {
  children: any,
  isBlog: bool,
  onClickHandler: func.isRequired,
};

export default AddUserButton;
