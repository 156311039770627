import React from 'react'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'

const SubmenuItem = ({ title, path }) => {
  return (
    <li key={title} className='header__menu--item'>
      <NavLink to={path} className='btns'>
        {title}
      </NavLink>
    </li>
  )
}

SubmenuItem.propTypes = {
  title: string.isRequired,
  path: string.isRequired,
}

export default SubmenuItem
