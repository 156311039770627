import React from 'react'
import { connect } from 'react-redux'
import { any, arrayOf, bool, func, objectOf } from 'prop-types'

import HeroImage from '../../../../components/HeroImage'
import { loginAction } from '../actions'
import LoginForm from './LoginForm'
import { ROUTE_TO_ALLERGY_TEST_ADMIN } from '../../../../constants/routes'

const LoginPage = ({ loading, errors, onSubmit, history }) => {
  const onSubmitHandler = async (values) => {
    try {
      const res = await onSubmit(values)
      const token = res.value.data.data.token.auth_token

      // eslint-disable-next-line no-undef
      localStorage.setItem('token', JSON.stringify(token))
      history.push(ROUTE_TO_ALLERGY_TEST_ADMIN)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className='page_wrapper'>
      <div className='content__main signin__page page'>
        <div className='site__width'>
          <section className='signin__section'>
            <div className='signin__section--left'>
              <div className='signin__section--text'>Welcome to</div>

              <div className='signin__section--logo'>
                <a className='btns btn-b' href='/'>
                  <i className='icon logo' />
                </a>
              </div>
              <div className='signin__section--from'>
                <LoginForm onSubmit={onSubmitHandler} submitErrors={errors} loading={loading} />
              </div>
            </div>
            <div className='signin__section--right'>
              <figure className='signin__section--image'>
                <HeroImage />
              </figure>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  loading: auth.inProcess,
  errors: auth.errors,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => dispatch(loginAction(data)),
})

LoginPage.propTypes = {
  loading: bool.isRequired,
  history: objectOf(any).isRequired,
  errors: arrayOf(any),
  onSubmit: func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
