import React from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { func } from 'prop-types'
import MainMenu from '../Nav'
import { logoutAction } from '../../containers/Auth/actions'
import { ROUTE_TO_ALLERGY_TEST_ADMIN, ROUTE_TO_LOGIN } from '../../../constants/routes'
import history from '../../../history'

const Header = ({ onLogout }) => {
  const onLogoutHandler = () => {
    onLogout()
      .then(() => {
        // eslint-disable-next-line no-undef
        localStorage.removeItem('token')
        history.push(ROUTE_TO_LOGIN)
      })
      .catch((e) => console.error(e))
  }

  return (
    <header className='header__main'>
      <div className='site__width'>
        <div className='header__main--logo'>
          <Link className='icons btn-b logo' to={ROUTE_TO_ALLERGY_TEST_ADMIN} title='Home' />
        </div>
        <nav className='header__main--nav'>
          <MainMenu />
        </nav>
        <div className='header__main--btns'>
          <button type='button' className='btns btn-b' onClick={onLogoutHandler}>
            <svg className='icons icons24x24'>
              <use xlinkHref='#logout' />
            </svg>
          </button>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  onLogout: func.isRequired,
}

export default connect(null, (dispatch) => ({ onLogout: () => dispatch(logoutAction()) }))(Header)
