import { createAction } from 'redux-actions';

import {
  CREATE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  GET_TEAM,
  GET_TEAM_MEMBER_BY_ID,
  UPDATE_TEAM_MEMBER,
} from './constants';

import {
  createTeamMemberApi,
  deleteTeamMemberApi,
  getTeamApi,
  updateTeamMemberApi,
  getTeamMemberByIdApi,
} from './api';

export const getTeamAction = createAction(
  GET_TEAM,
  async (data) => await getTeamApi(data)
);
export const createTeamMemberAction = createAction(
  CREATE_TEAM_MEMBER,
  async (data) => await createTeamMemberApi(data)
);
export const updateTeamMemberAction = createAction(
  UPDATE_TEAM_MEMBER,
  async (id, data) => await updateTeamMemberApi(id, data)
);
export const deleteTeamMemberAction = createAction(
  DELETE_TEAM_MEMBER,
  async (id) => await deleteTeamMemberApi(id)
);
export const getTeamMemberByIdAction = createAction(
  GET_TEAM_MEMBER_BY_ID,
  async (id) => await getTeamMemberByIdApi(id)
);
