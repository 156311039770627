import React, { useEffect } from 'react';
import { any, objectOf } from 'prop-types';
import { Link } from 'react-router-dom';
import StaticPage from '../../StaticPage';
import { ROUTE_TO_OUR_TEAM } from '../../../constants/routes';
import { connect } from 'react-redux';
import {
  clearTeamMemberByIdLandingAction,
  getTeamMemberByIdLandingAction,
} from '../OurTeam/actions';
import config from '../../../config';

const EmployeeDetails = ({
  match,
  teamMember,
  getTeamMemberById,
  clearTeamMemberById,
}) => {
  useEffect(() => {
    getTeamMemberById(match.params.id);

    return () => clearTeamMemberById();
  }, [match.params.id]);

  if (!teamMember) {
    return null;
  }

  const { full_name, user_type, description, logo_url } = teamMember;

  return (
    <StaticPage pageClassName='staff__page' withoutClassPage>
      <div className='site__width'>
        <figure className='staff__image'>
          <img
            className='image'
            src={`${config.REACT_APP_UPLOADS_URL}/${logo_url}`}
            alt={full_name}
          />
        </figure>
        <h1 className='staff__title'>{full_name}</h1>
        <div className='staff__subtitle'>{user_type}</div>
        <div className='staff__content'>{description}</div>
        <div className='staff__link'>
          <Link className='btns' to={ROUTE_TO_OUR_TEAM} title='Back to team'>
            Back to team <i className='icons i22x10 i-arrow_right' />
          </Link>
        </div>
      </div>
    </StaticPage>
  );
};

EmployeeDetails.propTypes = { match: objectOf(any).isRequired };

const mapStateToProps = ({ teamLanding }) => ({
  teamMember: teamLanding.teamMember,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamMemberById: (id) => dispatch(getTeamMemberByIdLandingAction(id)),
  clearTeamMemberById: () => dispatch(clearTeamMemberByIdLandingAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
