import React from 'react';
import { func, any, objectOf, string } from 'prop-types';

const ConfirmationModal = ({
  handler,
  title,
  message,
  mainButtonContent,
  cancelButtonContent = 'Cancel',
  hideModalHandler,
  target,
  goal,
}) => {
  let targetContent = target;
  if (goal === 'delete_appointment') {
    targetContent = `${target.patient.first_name} ${target.patient.last_name}`;
  } else if (goal === 'delete_article') {
    targetContent = target.title;
  } else if (goal === 'delete_team_member') {
    targetContent = target.full_name;
  } else if (goal === 'delete_faq') {
    targetContent = target.title;
  }

  return (
    <section id='popups' className='popup--main'>
      <div className='popup_overlay' />
      <div className='popup_block popup'>
        <div className='popup--title'>{title}</div>
        <form className='form--default'>
          <div className='popup--content text warning'>
            <p>{targetContent}</p>
            <div>{message}</div>
          </div>
          <div className='form--row form--row_submit'>
            <button
              type='button'
              className='btns reset_btn'
              data-toggle='class'
              data-target='#popups'
              onClick={hideModalHandler}>
              {cancelButtonContent}
            </button>
            <button
              type='button'
              onClick={() => handler(target && target.id)}
              className='btns delete_btn'>
              {mainButtonContent}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

ConfirmationModal.propTypes = {
  handler: func.isRequired,
  hideModalHandler: func.isRequired,
  title: string,
  message: string,
  goal: string,
  mainButtonContent: string,
  cancelButtonContent: string,
  target: objectOf(any),
};

export default ConfirmationModal;
