import React from 'react'
import { arrayOf, object } from 'prop-types'
import ResourceItem from './ResourceItem'

const ResourceList = ({ items }) => {
  return (
    <div className='allergyResources__list'>
      {items.map((resource) => (
        <ResourceItem resource={resource} key={resource.id} />
      ))}
    </div>
  )
}

ResourceList.propTypes = { items: arrayOf(object) }

export default ResourceList
