import React from 'react'
import { object, arrayOf } from 'prop-types'
import ArticlesItem from './ArticlesItem'

const MoreArticlesList = ({ blogs }) => {
  return (
    <div className='articlesMore__list'>
      {blogs.map((article) => (
        <ArticlesItem article={article} key={article.id} />
      ))}
    </div>
  )
}

MoreArticlesList.propTypes = {
  blogs: arrayOf(object),
}

export default MoreArticlesList
