export const ROUTE_TO_ROOT = '/';
export const ROUTE_TO_ABOUT_US = '/about_us';
export const ROUTE_TO_FAQ = '/faq';
export const ROUTE_TO_CONTACT_US = '/contact_us';
export const ROUTE_TO_FORMS = '/forms';
export const ROUTE_TO_ALLERGY_TYPES = '/allergy_types';
export const ROUTE_TO_ALLERGY_TYPES_WITH_ID = '/allergy_types/:id?';
export const routeToAllergyType = (id) => `/allergy_types/${id}`;
export const ROUTE_TO_ALLERGY_SYMPTOMS = '/allergy_symptoms';
export const ROUTE_TO_ALLERGY_RESOURCES = '/allergy_resources';
export const ROUTE_TO_ALLERGY_TEST = '/allergy_test';
export const routeToArticle = (id) => `/article/${id}`;
export const ROUTE_TO_OUR_TEAM = '/our_team';
export const ROUTE_TO_OUR_ADVISORS = '/our_advisors';
export const ROUTE_TO_EMPLOYEE = '/employee';
export const ROUTE_TO_EMPLOYEE_WITH_ID = '/employee/:id';
export const routeToEmployee = (id) => `/employee/${id}`;
export const routeToAdvisor = (id) => `/our_advisors/${id}`;
export const ROUTE_TO_ADVISOR_WITH_ID = '/our_advisors/:id';

export const ROUTE_TO_BLOG = '/blog';
export const ROUTE_TO_ARTICLE = '/article';
export const ROUTE_TO_ARTICLE_WITH_ID = '/article/:slug';
export const ROUTE_TO_SESSIONS_AVAILABILITY = '/sessions_availability';
export const ROUTE_TO_SESSIONS_AVAILABILITY_WITH_ID =
  '/sessions_availability/:id';
export const routeToBookTest = (id) => `/sessions_availability/${id}`;
export const ROUTE_TO_BOOKING_FORM_PAGE = '/booking';
export const ROUTE_TO_BOOKING_CONFIRM_PAGE = '/confirm';
export const ROUTE_TO_PAYMENT = '/payment';

// Admin routes
export const ROUTE_TO_LOGIN = '/login';
export const ROUTE_TO_ALLERGY_TEST_ADMIN = '/admin';
export const ROUTE_TO_APPOINTMENT_ADMIN = '/admin/patients/:id';
export const routeToAppointmentAdmin = (id) => `/admin/patients/${id}`;
export const ROUTE_TO_VIDEO_ADMIN = '/admin/video';
export const ROUTE_TO_BOOKING_ADMIN = '/admin/appointment';
export const ROUTE_TO_BOOKING_ADMIN_WITH_TYPE = '/admin/appointment/:type';
export const routeToBookingAdmin = (type) => `/admin/appointment/${type}`;
export const ROUTE_TO_BLOG_ADMIN = '/admin/blog';
export const ROUTE_TO_ARTICLE_ADMIN = '/admin/article';
export const ROUTE_TO_ARTICLE_ADMIN_WITH_TYPE = '/admin/article/:type';
export const routeToArticleAdmin = (type) => `/admin/article/${type}`;
export const ROUTE_TO_AVAILABILITY_ADMIN = '/admin/availability';
export const ROUTE_TO_TEAM_ADMIN = '/admin/team';
export const ROUTE_TO_ADD_TEAM_MEMBER_ADMIN = '/admin/team/add';
export const ROUTE_TO_EDIT_TEAM_MEMBER_BY_ID_ADMIN = '/admin/team/edit/:id';
export const routeToEditTeamMemberAdmin = (id) => `/admin/team/edit/${id}`;
export const ROUTE_TO_FAQ_ADMIN = '/admin/faq';
export const ROUTE_TO_EDIT_FAQ_BY_ID_ADMIN = '/admin/faq/edit/:id';
export const routeToEditFAQByIdAdmin = (id) => `/admin/faq/edit/${id}`;
export const ROUTE_TO_ADD_FAQ_ADMIN = '/admin/faq/add';
