import React from 'react'

const FoodAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Food Allergy</h2>
      <p>
        The most common food allergies in children are milk, egg, peanut, tree nut, soy, wheat, fish and shellfish. 2-3%
        of children suffer from food allergies.
      </p>
      <p>
        Cow’s milk allergy (CMA) is one of the most common food allergies in infants and young children. Approximately
        2% of children under 4 years suffer from this condition. However, most children will grow out of their cow’s
        milk allergy by age 3-5 years.
      </p>
      <p>
        Egg allergy is another common allergy diagnosed in children. Egg allergy occurs as a result of overreaction of
        the immune system in response to the proteins present in both egg white and egg yolk. However, the good news is
        that two thirds of children are no longer egg allergic by age 10.
      </p>
    </div>
  )
}

export default FoodAllergyInfo
