import { createAction } from 'redux-actions';

import { GET_PATIENT_BY_ID, GET_PATIENTS } from './constants';
import { getPatientApiAdmin, getPatientByIdApiAdmin } from './api';

export const getPatientsAction = createAction(
  GET_PATIENTS,
  async (data) => await getPatientApiAdmin(data)
);

export const getPatientByIdAction = createAction(
  GET_PATIENT_BY_ID,
  async (id) => await getPatientByIdApiAdmin(id)
);
