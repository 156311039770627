import { createAction } from 'redux-actions';

import {
  CREATE_FAQ,
  DELETE_FAQ,
  GET_FAQ,
  GET_FAQ_BY_ID,
  UPDATE_FAQ,
} from './constants';

import {
  createFaqApi,
  deleteFaqApi,
  getFaqApi,
  updateFaqApi,
  getFaqByIdApi,
} from './api';

export const getFaqAction = createAction(
  GET_FAQ,
  async (data) => await getFaqApi(data)
);
export const createFaqAction = createAction(
  CREATE_FAQ,
  async (data) => await createFaqApi(data)
);
export const updateFaqAction = createAction(
  UPDATE_FAQ,
  async (id, data) => await updateFaqApi(id, data)
);
export const deleteFaqAction = createAction(
  DELETE_FAQ,
  async (id) => await deleteFaqApi(id)
);
export const getFaqByIdAction = createAction(
  GET_FAQ_BY_ID,
  async (id) => await getFaqByIdApi(id)
);
