import React from 'react'
import { arrayOf, node, oneOfType, string } from 'prop-types'

const CellEditor = ({ children, className }) => {
  return (
    <ul className={className}>
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{child}</li>
      ))}
    </ul>
  )
}

CellEditor.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
}

export default CellEditor
