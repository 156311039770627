import React from 'react'
import StaticPage from '../../StaticPage'
import ResourceList from './components/ResourceList'
import { ALLERGY_RESOURCES } from '../../../constants/companyData'

export default function AllergyResourcesPage() {
  return (
    <StaticPage pageClassName='allergyResources__page'>
      <div className='site__width'>
        <h1 className='page__title'>Allergy resources</h1>
        <div className='page__subtitle'>More information on allergy</div>
        <ResourceList items={ALLERGY_RESOURCES} />
      </div>
    </StaticPage>
  )
}
