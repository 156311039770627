import React, { useState } from 'react';
import { initiatePaymentAction } from '../../containers/Booking/actions';
import { connect } from 'react-redux';

import { func, number, string } from 'prop-types';
import { initiatePaymentApi } from '../../containers/Booking/api';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { successNotificationAction } from '../../containers/Notification/actions';
import { ROUTE_TO_ROOT } from '../../constants/routes';

const PaymentForm = ({
  onSubmit,
  price,
  patient,
  history,
  isVideo,
  successNotification,
}) => {
  const [submitStatus, setSubmitStatus] = useState(false);
  const [completeCard, setCompleteCard] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const data = {
    customer_email: patient.email,
    price: price,
    patient_id: patient.id,
    // session_id: patient.session_id,
    token: null,
  };
  const stripe = useStripe();
  const elements = useElements();

  const completeOrder = (clientSecret) => {
    stripe
      ?.retrievePaymentIntent(clientSecret)
      .then((res) => {
        const paymentIntent = res.paymentIntent;
      })
      .then(() => {
        setSubmitStatus(false);
        setIsSuccess(true);
      })
      .catch((res) => console.log(res));
  };

  const handleSubmit = async () => {
    if (completeCard) {
      setSubmitStatus(true);
      if (!stripe || !elements) {
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      const payment_method = payload.paymentMethod.id;

      data.token = payment_method;

      const res = await initiatePaymentApi(data);

      if (res.status === 200) {
        stripe
          .confirmCardPayment(res.data.payment_intent_client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          })
          .then(() => {
            successNotification(
              isVideo
                ? 'You have successfully booked for the Video Call Appointment.'
                : 'You have successfully booked for the Allergy Test Appointment.'
            );
            history.push(ROUTE_TO_ROOT);
            // completeOrder(res.data.payment_intent_client_secret);
          });
      }
    } else alert('FILL THE CARD INFO');
  };
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        className='PaymentForm_form'>
        <div className='PaymentForm_cardDiv'>
          {isSuccess ? (
            <div className='isSuccess'>Payment Complete!</div>
          ) : (
            <div>
              <div className='card_element'>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                        border: '1px solid #0d0d0d',
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
              <input
                className='btns btn-b btn-red_b btn-appnt'
                disabled={submitStatus ? true : false}
                type='submit'
                value={`Pay ${Number(price) / 100} €`}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  initiatePayment: (data) => dispatch(initiatePaymentAction(data)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
});

PaymentForm.propTypes = {
  onSubmit: func.isRequired,
  renderButton: func.isRequired,
  price: number.isRequired,
  email: string.isRequired,
};

export default connect(null, mapDispatchToProps)(PaymentForm);
