import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { any, func, objectOf } from 'prop-types';
import moment from 'moment';
import { testSchema, videoSchema } from './validate';
import DatePicker from '../../../../../components/DatePicker';
import history from '../../../../../history';

const phoneRegExp = /^\p{L}+$/u;

// eslint-disable-next-line react/prefer-stateless-function
class BookingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      surname: '',
      birthDate: undefined,
      birthIsTouched: false,
      // eslint-disable-next-line react/destructuring-assignment
      isChild: this.props.bookingData.age === 'child',
      parentFirstName: '',
      parentSurname: '',
      email: '',
      tel: '',
      GPName: '',
      GPAddress: '',
      GPEmail: '',
      GPAttachment: null,
      testReason: '',
      antiHistamine: false,
      agreement: false,
    };
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'birthDate' || name === 'GPAttachment') return;
    this.setState({ [name]: value });
  };

  onCancelHandler = () => {
    this.setState({
      birthDate: undefined,
      birthIsTouched: false,
      GPAttachment: null,
    });
    history.goBack();
  };

  render() {
    const { birthDate, birthIsTouched, GPAttachment, isChild } = this.state;

    const {
      bookingData: {
        price,
        age,
        data: date,
        start_time,
        end_time,
        is_video_call: isVideo,
      },
      onFormSubmit,
    } = this.props;

    const normalizedStartTime = moment(start_time, 'HH"mm').format('LT');
    const normalizedEndTime = moment(end_time, 'HH"mm').format('LT');
    const normalizedDate = `${moment(date, 'DD/MM/YYYY').format(
      'MMMM DD, YYYY'
    )} at ${normalizedStartTime} - ${normalizedEndTime}`;

    const fileUploaderContent = GPAttachment ? (
      <div className='form__file-btn btns btn-b form__file-uploaded'>
        <i className='icons i-attachment' />
        {GPAttachment.name.substr(0, 27)}
        <i
          className='icons i24x24 i-close_red delete-attachment'
          title='Delete'
          onClick={() => this.setState({ GPAttachment: null })}
        />
      </div>
    ) : (
      <button className='form__file-btn btns btn-b' type='button'>
        <i className='icons i-attachment' />
        Add file
        <Field
          className='form__file'
          id='GPAttachment'
          type='file'
          accept='.doc,.docx,.pdf'
          name='GPAttachment'
          value={undefined}
          onChange={(e) => this.setState({ GPAttachment: e.target.files[0] })}
        />
      </button>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          surname: '',
          isChild,
          parentFirstName: '',
          parentSurname: '',
          email: '',
          tel: '',
          GPName: '',
          GPAddress: '',
          GPEmail: '',
          GPAttachment: null,
          testReason: '',
          antiHistamine: false,
          agreement: false,
        }}
        validationSchema={isVideo ? videoSchema : testSchema}
        onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
          if (!birthDate) {
            return this.setState({ birthIsTouched: true });
          }

          try {
            setSubmitting(true);
            await onFormSubmit(this.state);

            resetForm();
            setSubmitting(false);
            setStatus({ success: true });
          } catch (error) {
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}>
        {({ values, isSubmitting }) => {
          return (
            <Form className='form__default' onChange={this.onChangeHandler}>
              <div className='booking__formHeader'>
                <p>
                  Please confirm that you would like to request the following
                  appointment:
                </p>
                <h2 className='booking__formHeader--title'>{`${
                  age === 'adult' ? 'Adult' : 'Child'
                } appointment`}</h2>
                <div className='booking__formHeader--time'>
                  {normalizedDate}
                </div>
                <div className='booking__formHeader--price'>
                  Cost: € {price}
                </div>
              </div>
              <div className='booking__formBody'>
                <div>
                  <p>
                    Please confirm that you would like to request the following
                    appointment:
                  </p>
                </div>
                <div className='form__row'>
                  <Field
                    name='firstName'
                    className='form__input'
                    placeholder='Patient First Name'
                  />
                  <ErrorMessage
                    name='firstName'
                    component='div'
                    className='validation-error'
                  />
                </div>

                <div className='form__row'>
                  <Field
                    name='surname'
                    className='form__input'
                    placeholder='Patient Surname'
                  />
                  <ErrorMessage
                    name='surname'
                    component='div'
                    className='validation-error'
                  />
                </div>

                <div className='form__row calendar'>
                  <DatePicker
                    date={birthDate}
                    setDate={(value) => this.setState({ birthDate: value })}
                    touch={() => this.setState({ birthIsTouched: true })}
                  />
                  {!birthDate && birthIsTouched ? (
                    <div className='validation-error'>Required</div>
                  ) : null}
                </div>

                {isChild ? (
                  <>
                    <div className='form__row parent'>
                      <Field
                        name='parentFirstName'
                        className='form__input'
                        placeholder='First name Parent / Guardian'
                      />
                      <ErrorMessage
                        name='parentFirstName'
                        component='div'
                        className='validation-error'
                      />
                    </div>
                    <div className='form__row parent'>
                      <Field
                        name='parentSurname'
                        className='form__input'
                        placeholder='Surname Parent / Guardian'
                      />
                      <ErrorMessage
                        name='parentSurname'
                        component='div'
                        className='validation-error'
                      />
                    </div>
                  </>
                ) : null}

                <div className='form__row'>
                  <Field
                    name='email'
                    className='form__input'
                    placeholder='Contact Email'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='validation-error'
                  />
                </div>

                <div className='form__row'>
                  <Field
                    type='string'
                    onKeyDown={(e) => {
                      if (e.key.length === 1 && phoneRegExp.test(e.key)) {
                        // allow control keys, numbers, and symbols
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    name='tel'
                    className='form__input'
                    placeholder='Contact Phone Number'
                  />
                  <ErrorMessage
                    name='tel'
                    component='div'
                    className='validation-error'
                  />
                </div>

                {!isVideo && !isChild ? (
                  <>
                    <div className='form__row'>
                      <Field
                        className='form__input'
                        name='GPName'
                        placeholder=' GP / Consultant Name'
                      />
                      <ErrorMessage
                        name='GPName'
                        component='div'
                        className='validation-error'
                      />
                    </div>
                    <div className='form__row'>
                      <Field
                        className='form__input'
                        name='GPAddress'
                        placeholder=' GP / Consultant Address'
                      />
                      <ErrorMessage
                        name='GPAddress'
                        component='div'
                        className='validation-error'
                      />
                    </div>
                    <div className='form__row'>
                      <Field
                        className='form__input'
                        type='email'
                        name='GPEmail'
                        placeholder=' GP / Consultant Email'
                      />
                      <ErrorMessage
                        name='GPEmail'
                        component='div'
                        className='validation-error'
                      />
                    </div>

                    <div className='form__row attachment'>
                      <p>
                        Please upload the referral letter (PDF or Word only)
                      </p>
                      {fileUploaderContent}
                    </div>
                    <div className='form__row'>
                      <Field
                        as='textarea'
                        className='form__textarea'
                        name='testReason'
                        placeholder='Please enter what you need to be tested for (e.g. dairy, nuts, dust mites, dog dander).'
                      />
                      <ErrorMessage
                        name='testReason'
                        component='div'
                        className='validation-error'
                      />
                    </div>
                  </>
                ) : null}
              </div>

              {!isVideo ? (
                <div className='booking__formFooter'>
                  <div className='form__row'>
                    <h5>
                      All antihistamine medication must be discontinued 5 days
                      prior to the test.
                    </h5>
                    <p>Please check box confirming that you have read this.</p>
                    <Field
                      type='checkbox'
                      name='antiHistamine'
                      id='antiHistamine'
                      className='form__checkbox'
                      checked={values.antiHistamine}
                    />
                    <label
                      htmlFor='antiHistamine'
                      className='form__checkbox--label'>
                      Yes
                    </label>
                    <ErrorMessage
                      name='antiHistamine'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                  <div className='form__row'>
                    <h6>
                      I have read the Patient Information/Consent Form (found in
                      the forms section) on allergy skin prick testing. I
                      understand it and I consent to the skin prick test being
                      performed
                    </h6>
                    <p>Please check box confirming that you have read this.</p>

                    <Field
                      type='checkbox'
                      name='agreement'
                      id='agreement'
                      className='form__checkbox'
                      checked={values.agreement}
                    />
                    <label
                      htmlFor='agreement'
                      className='form__checkbox--label'>
                      Yes
                    </label>
                    <ErrorMessage
                      name='agreement'
                      component='div'
                      className='validation-error'
                    />
                  </div>
                </div>
              ) : null}

              <div className='form__row--btns'>
                <input
                  className='btns btn-i btn-red_b form__submit'
                  type='submit'
                  name='submit'
                  value={`${
                    isSubmitting ? 'Submitting...' : 'Book Appointment'
                  }`}
                />
                <input
                  className='btns btn-i btn-white_b form__reset'
                  type='reset'
                  name='reset'
                  onClick={this.onCancelHandler}
                  value='Cancel'
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

BookingForm.propTypes = {
  bookingData: objectOf(any),
  onFormSubmit: func.isRequired,
};

export default BookingForm;
