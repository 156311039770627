import typeToReducer from 'type-to-reducer'
import { SEND_CONTACT_FORM } from './constants'

export const initialState = {
  loading: false,
  sent: false,
  errors: [],
}

export default typeToReducer(
  {
    [SEND_CONTACT_FORM]: {
      START: (state, action) => {
        return {
          ...state,
          loading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          sent: true,
          loading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          loading: false,
        }
      },
    },
  },
  initialState
)
