import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import {
  ROUTE_TO_ROOT,
  ROUTE_TO_ABOUT_US,
  ROUTE_TO_EMPLOYEE_WITH_ID,
  ROUTE_TO_OUR_TEAM,
  ROUTE_TO_OUR_ADVISORS,
  ROUTE_TO_FORMS,
  ROUTE_TO_ALLERGY_TYPES_WITH_ID,
  ROUTE_TO_ALLERGY_SYMPTOMS,
  ROUTE_TO_ALLERGY_RESOURCES,
  ROUTE_TO_ALLERGY_TEST,
  ROUTE_TO_BLOG,
  ROUTE_TO_ARTICLE_WITH_ID,
  ROUTE_TO_FAQ,
  ROUTE_TO_CONTACT_US,
  ROUTE_TO_BOOKING_FORM_PAGE,
  ROUTE_TO_SESSIONS_AVAILABILITY_WITH_ID,
  ROUTE_TO_BOOKING_CONFIRM_PAGE,
  ROUTE_TO_PAYMENT,
  ROUTE_TO_LOGIN,
  ROUTE_TO_ALLERGY_TEST_ADMIN,
  ROUTE_TO_AVAILABILITY_ADMIN,
  ROUTE_TO_VIDEO_ADMIN,
  ROUTE_TO_BOOKING_ADMIN_WITH_TYPE,
  ROUTE_TO_BLOG_ADMIN,
  ROUTE_TO_ARTICLE_ADMIN_WITH_TYPE,
  ROUTE_TO_TEAM_ADMIN,
  ROUTE_TO_TEAM_ADMIN_WITH_TYPE,
  ROUTE_TO_ADD_TEAM_MEMBER_ADMIN,
  ROUTE_TO_EDIT_TEAM_MEMBER_BY_ID_ADMIN,
  ROUTE_TO_FAQ_ADMIN,
  ROUTE_TO_FAQ_ADMIN_WITH_ID,
  ROUTE_TO_ADD_FAQ_ADMIN,
  ROUTE_TO_EDIT_FAQ_BY_ID_ADMIN,
  ROUTE_TO_ADVISOR_WITH_ID,
  ROUTE_TO_APPOINTMENT_ADMIN,
} from '../../constants/routes';

import Home from '../../components/Pages/Home';
import AboutUs from '../../components/Pages/AboutUs';
import OurTeam from '../../components/Pages/OurTeam';
import EmployeeDetails from '../../components/Pages/EmployeeDetails';
import OurAdvisors from '../../components/Pages/OurAdvisors';
import FormsPage from '../../components/Pages/Forms';
import AllergyTypesPage from '../../components/Pages/AllergyTypes';
import AllergySymptomsPage from '../../components/Pages/AllergySymptoms';
import AllergyResourcesPage from '../../components/Pages/AllergyResources';
import AllergyTestPage from '../../components/Pages/AllergyTest';
import BlogPage from '../Blog';
import ArticlePage from '../Blog/Article';
import FaqPage from '../../components/Pages/Faqs';
import ContactUs from '../../components/Pages/ContactUs';
import BookingTest from '../Booking/BookingTest';
import BookingFormPage from '../Booking/BookingPage';
import ConfirmPage from '../Booking/BookingPage/components/ConfirmPage';
import StripeCheckout from '../../components/PaymentForm';
import { sendContactFormAction } from './actions';
import LoginPage from '../../admin/containers/Auth/Login';
import PrivateRoute from '../../components/PrivateRoute';
import AllergyTestAdmin from '../../admin/containers/AllergyTest';
import StrictlyPublicRoute from '../../components/StrictlyPublicRoute';
import AvailabilityPage from '../../admin/containers/Availability';
import BookingAppointmentAdmin from '../../admin/containers/BookingAppointment';
import AdminBlog from '../../admin/containers/Blog';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../Notification/actions';
import ArticlePageAdmin from '../../admin/containers/Blog/ArticleAdmin';
import AdminTeam from '../../admin/containers/Team';
import TeamMemberAdminPage from '../../admin/containers/Team/TeamMember';
import AdminFAQ from '../../admin/containers/FAQ';
import FAQAdminPage from '../../admin/containers/FAQ/FAQPage';
import AdvisorDetails from '../../components/Pages/AdvisorDetails';
import AdminAppointmentsPage from '../../admin/containers/Appointments';
import PatientAppointment from '../../admin/containers/Appointments/PatientAppointment';

const TopPageView = () => {
  // eslint-disable-next-line no-undef
  window.scrollTo(0, 0);
  return <></>;
};

const App = ({
  loading,
  sendContactForm,
  successNotification,
  errorNotification,
}) => {
  return (
    <div>
      <Route component={TopPageView} />
      <Switch>
        <Route exact path={ROUTE_TO_ROOT} component={Home} />
        <Route exact path={ROUTE_TO_ALLERGY_TEST} component={AllergyTestPage} />
        <Route exact path={ROUTE_TO_ABOUT_US} component={AboutUs} />
        <Route exact path={ROUTE_TO_OUR_TEAM} component={OurTeam} />
        {/* <Route exact path={ROUTE_TO_OUR_ADVISORS} component={OurAdvisors} />
        <Route
          exact
          path={ROUTE_TO_ADVISOR_WITH_ID}
          component={AdvisorDetails}
        /> */}
        <Route
          exact
          path={ROUTE_TO_EMPLOYEE_WITH_ID}
          component={EmployeeDetails}
        />
        <Route exact path={ROUTE_TO_FORMS} component={FormsPage} />
        <Route
          exact
          path={ROUTE_TO_ALLERGY_TYPES_WITH_ID}
          component={AllergyTypesPage}
        />
        <Route
          exact
          path={ROUTE_TO_ALLERGY_SYMPTOMS}
          component={AllergySymptomsPage}
        />
        <Route
          exact
          path={ROUTE_TO_ALLERGY_RESOURCES}
          component={AllergyResourcesPage}
        />
        <Route exact path={ROUTE_TO_BLOG} component={BlogPage} />
        <Route exact path={ROUTE_TO_ARTICLE_WITH_ID} component={ArticlePage} />
        <Route exact path={ROUTE_TO_FAQ} component={FaqPage} />
        <Route
          exact
          path={ROUTE_TO_CONTACT_US}
          component={() => (
            <ContactUs
              loading={loading}
              onSubmit={sendContactForm}
              successNotification={successNotification}
              errorNotification={errorNotification}
            />
          )}
        />
        <Route
          exact
          path={ROUTE_TO_SESSIONS_AVAILABILITY_WITH_ID}
          component={BookingTest}
        />
        {/* <Route
          exact
          path={ROUTE_TO_BOOKING_FORM_PAGE}
          component={BookingFormPage}
        /> */}
        <Route
          exact
          path={ROUTE_TO_BOOKING_CONFIRM_PAGE}
          component={ConfirmPage}
        />
        <Route exact path={ROUTE_TO_PAYMENT} component={StripeCheckout} />
        {/* AllergyTest routes */}
        <StrictlyPublicRoute
          exact
          path={ROUTE_TO_LOGIN}
          component={LoginPage}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_ALLERGY_TEST_ADMIN}
          component={AdminAppointmentsPage}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_APPOINTMENT_ADMIN}
          component={PatientAppointment}
        />
        {/* <PrivateRoute
          exact
          path={ROUTE_TO_ALLERGY_TEST_ADMIN}
          component={AllergyTestAdmin}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_VIDEO_ADMIN}
          component={AllergyTestAdmin}
        /> */}
        <PrivateRoute
          exact
          path={ROUTE_TO_AVAILABILITY_ADMIN}
          component={AvailabilityPage}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_BOOKING_ADMIN_WITH_TYPE}
          component={BookingAppointmentAdmin}
        />
        <PrivateRoute exact path={ROUTE_TO_BLOG_ADMIN} component={AdminBlog} />
        <PrivateRoute
          exact
          path={ROUTE_TO_ARTICLE_ADMIN_WITH_TYPE}
          component={ArticlePageAdmin}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_ADD_TEAM_MEMBER_ADMIN}
          component={TeamMemberAdminPage}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_EDIT_TEAM_MEMBER_BY_ID_ADMIN}
          component={TeamMemberAdminPage}
        />
        <PrivateRoute exact path={ROUTE_TO_TEAM_ADMIN} component={AdminTeam} />
        <PrivateRoute
          exact
          path={ROUTE_TO_EDIT_FAQ_BY_ID_ADMIN}
          component={FAQAdminPage}
        />
        <PrivateRoute
          exact
          path={ROUTE_TO_ADD_FAQ_ADMIN}
          component={FAQAdminPage}
        />
        <PrivateRoute exact path={ROUTE_TO_FAQ_ADMIN} component={AdminFAQ} />
        <Redirect to={ROUTE_TO_ROOT} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ app: { loading } }) => ({ loading });
const mapDispatchToProps = (dispatch) => ({
  sendContactForm: (data) => dispatch(sendContactFormAction(data)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

App.propTypes = {
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  loading: bool.isRequired,
  sendContactForm: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
