import React from 'react'

import { objectOf, any } from 'prop-types'
import StaticPage from '../../StaticPage'
import TypeList from './components/TypesList'
import TypeInfoWrapper from './components/TypeInfoWrapper'

const AllergyTypesPage = ({ match }) => {
  const id = match.params.id || 'oral'

  return (
    <StaticPage withoutClassPage pageClassName='allergyTypes__page'>
      <div className='site__width'>
        <h1 className='page__title'>Types of Allergies</h1>
        <div className='page__subtitle'>More information on allergy</div>
        <div id='tabs' className='allergyTypes__content'>
          <div className='allergyTypes__nav'>
            <TypeList page={id} />
          </div>
          <TypeInfoWrapper page={id} />
        </div>
      </div>
    </StaticPage>
  )
}

AllergyTypesPage.propTypes = {
  match: objectOf(any),
}

export default AllergyTypesPage
