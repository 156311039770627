import React, { PureComponent } from 'react';
import { bool, func, any, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import StaticPage from '../../../../components/StaticPage';
import toBase64 from '../../../../utils/toBase64';
import {
  createTeamMemberAction,
  getTeamMemberByIdAction,
  updateTeamMemberAction,
} from '../actions';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../../../../containers/Notification/actions';
import {
  ROUTE_TO_BLOG_ADMIN,
  ROUTE_TO_TEAM_ADMIN,
} from '../../../../constants/routes';
import config from '../../../../config';

class TeamMemberAdminPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      coverImage: null,
      coverBase64: null,
      full_name: '',
      description: '',
      user_type: '',
      logo: undefined,
      errors: {},
    };
  }

  componentDidMount() {
    const { pathname } = this.props.history.location;

    const isEdit = pathname.includes('edit');

    if (isEdit) {
      const { id } = this.props.match.params;
      this.props.getTeamMemberById(id);
      this.setState({ isEdit: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.logo !== this.state.logo) {
      const { logo } = this.state;
      logo && toBase64(logo).then((res) => this.setState({ coverBase64: res }));
    }

    if (prevProps.teamMember !== this.props.teamMember) {
      this.setState({
        ...this.props.teamMember,
        coverImage: this.props.teamMember.logo_url,
        imageFromUrl: true,
      });
    }
  }

  onSaveHandler = async () => {
    const {
      contentState,
      full_name,
      description,
      user_type,
      logo,
      coverImage,
      isEdit,
      id,
    } = this.state;
    const {
      history,
      createTeamMember,
      updateTeamMember,
      successNotification,
      errorNotification,
    } = this.props;

    const newErrors = {};
    if (!full_name) newErrors.full_name = 'required';
    if (!description) newErrors.description = 'required';
    if (!user_type) newErrors.user_type = 'required';

    if (Object.keys(newErrors).length)
      return this.setState({ errors: newErrors });

    const formData = new FormData();
    formData.append('full_name', full_name);
    formData.append('description', description);
    formData.append('user_type', user_type);
    logo && formData.append('logo', logo);

    // eslint-disable-next-line no-unused-expressions
    isEdit
      ? await updateTeamMember(id, formData)
      : await createTeamMember(formData);

    const message = isEdit
      ? 'Team member was successfully updated'
      : 'Team member was successfully created';
    successNotification(message);

    history.push(ROUTE_TO_TEAM_ADMIN);
  };

  render() {
    const {
      title,
      coverImage,
      coverBase64,
      imageFromUrl,
      isEdit,
      errors,
      full_name,
      description,
      user_type,
      logo,
    } = this.state;
    const { history } = this.props;

    const renderImage = () => {
      if (imageFromUrl && coverImage) {
        return (
          <img
            className='image article-cover-image'
            src={`${config.REACT_APP_UPLOADS_URL}${coverImage}`}
            title='Remove image'
            alt='ArticleImage'
            onClick={() =>
              this.setState({ coverImage: null, imageFromUrl: false })
            }
          />
        );
      }
      if (coverBase64) {
        return (
          <div
            className='form--row f form--row_file2'
            onClick={() =>
              this.setState({ coverBase64: null, coverImage: null })
            }
            title='Remove this picture'>
            <img
              src={coverBase64}
              className='article-cover-image'
              alt='Article cover'
            />
          </div>
        );
      }
      return (
        <div className='form--row f form--row_file2'>
          <div className='form--label'>Choose the main pic</div>
          <button className='btns btn-b btn-fileupload2' type='button'>
            <i className='icons i-attachment' />
            Add file
            <input
              className='form__file'
              type='file'
              accept='.jpg,.jpeg,.png'
              name='logo'
              onChange={(e) => this.setState({ logo: e.target.files[0] })}
            />
          </button>
        </div>
      );
    };

    return (
      <StaticPage pageClassName='blog__page' isAdmin noFooter>
        <div className='site__width'>
          <section className='blog--edit section--content'>
            <form className='form--default'>
              <header className='blog--edit_header clearfix'>
                <div className='blog--edit_header-text'>
                  <h1
                    className='blog--edit_header-title form--title uppercase'
                    style={{ textAlign: 'center' }}>
                    {isEdit ? 'Edit team member' : 'Create new team member'}
                  </h1>
                </div>
              </header>
              <div
                className='blog--edit_content'
                style={{ justifyContent: 'center' }}>
                <div style={{ width: '50%' }}>
                  {renderImage()}
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='title'>
                      Full name
                    </label>
                    <input
                      className='form--input'
                      id='full_name'
                      type='text'
                      name='full_name'
                      value={full_name}
                      onChange={(e) =>
                        this.setState({
                          full_name: e.target.value,
                          errors: { ...errors, full_name: null },
                        })
                      }
                    />
                    {errors.full_name ? (
                      <p className='validation-error'>Full name is required</p>
                    ) : null}
                  </div>
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='user_type'>
                      User type
                    </label>
                    <input
                      className='form--input'
                      id='user_type'
                      type='text'
                      name='user_type'
                      value={user_type}
                      onChange={(e) =>
                        this.setState({
                          user_type: e.target.value,
                          errors: { ...errors, user_type: null },
                        })
                      }
                    />
                    {errors.user_type ? (
                      <p className='validation-error'>User type is required</p>
                    ) : null}
                  </div>
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='description'>
                      Description
                    </label>
                    <textarea
                      style={{ resize: 'vertical' }}
                      className='form--input'
                      id='description'
                      name='description'
                      value={description}
                      onChange={(e) =>
                        this.setState({
                          description: e.target.value,
                          errors: { ...errors, description: null },
                        })
                      }
                    />
                    {errors.description ? (
                      <p className='validation-error'>
                        Description is required
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className='form--row form--row_submit'>
                  <button
                    type='button'
                    className='btns btn-i reset_btn'
                    onClick={() => history.push(ROUTE_TO_BLOG_ADMIN)}>
                    CANCEL
                  </button>
                  <button
                    type='button'
                    className='btns btn-i submit_btn'
                    onClick={this.onSaveHandler}>
                    Create new
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </StaticPage>
    );
  }
}

const mapStateToProps = ({ team }) => ({
  loading: team.loading,
  errors: team.errors,
  teamMember: team.teamMember,
});

const mapDispatchToProps = (dispatch) => ({
  createTeamMember: async (data) =>
    await dispatch(createTeamMemberAction(data)),
  updateTeamMember: async (id, data) =>
    await dispatch(updateTeamMemberAction(id, data)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
  getTeamMemberById: async (id) => await dispatch(getTeamMemberByIdAction(id)),
});

TeamMemberAdminPage.propTypes = {
  loading: bool,
  createTeamMember: func.isRequired,
  updateTeamMember: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  history: objectOf(any),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberAdminPage);
