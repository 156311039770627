import { createAction } from 'redux-actions';

import {
  CLEAR_TEAM_MEMBER_BY_ID_LANDING,
  GET_TEAM_LANDING,
  GET_TEAM_MEMBER_BY_ID_LANDING,
} from './constants';

import { getTeamLandingApi, getTeamMemberByIdApiLanding } from './api';

export const getTeamLandingAction = createAction(
  GET_TEAM_LANDING,
  async (data) => await getTeamLandingApi(data)
);

export const getTeamMemberByIdLandingAction = createAction(
  GET_TEAM_MEMBER_BY_ID_LANDING,
  async (id) => await getTeamMemberByIdApiLanding(id)
);

export const clearTeamMemberByIdLandingAction = createAction(
  CLEAR_TEAM_MEMBER_BY_ID_LANDING
);
