import typeToReducer from 'type-to-reducer';
import {
  DELETE_SESSION_ADMIN,
  GET_RESULTS,
  GET_SESSIONS_ADMIN,
  UPDATE_SESSIONS_ADMIN,
} from './constants';
import { CREATE_PATIENT } from '../../../containers/Booking/constants';

export const initialState = {
  loading: false,
  deleteLoading: false,
  createLoading: false,
  sessions: [],
  availabilityErrors: [],
};

export default typeToReducer(
  {
    [GET_SESSIONS_ADMIN]: {
      START: (state, action) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          sessions: action.payload.data.sessions,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          availabilityErrors: [
            ...state.availabilityErrors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [GET_RESULTS]: {
      START: (state, action) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          availabilityErrors: [
            ...state.availabilityErrors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [UPDATE_SESSIONS_ADMIN]: {
      START: (state, action) => {
        return {
          ...state,
          loading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          sessions: [
            ...action.payload.data.sessions_create,
            ...action.payload.data.sessions_update,
          ],
          loading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          availabilityErrors: [
            ...state.availabilityErrors,
            { message: action.payload.message, config: action.payload.config },
          ],
          loading: false,
        };
      },
    },
    [DELETE_SESSION_ADMIN]: {
      START: (state, action) => {
        return {
          ...state,
          deleteLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          deleteLoading: false,
        };
      },
      FAIL: (state, action) => {
        return {
          ...state,
          availabilityErrors: [
            ...state.availabilityErrors,
            { message: action.payload.message, config: action.payload.config },
          ],
          deleteLoading: false,
        };
      },
    },
    [CREATE_PATIENT]: {
      START: (state, action) => {
        return {
          ...state,
          createLoading: true,
        };
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          createLoading: false,
        };
      },
      FAIL: (state, action) => {
        console.log({ state, action });

        return {
          ...state,
          availabilityErrors: [
            ...state.errors,
            { message: action.payload.message, config: action.payload.config },
          ],
          createLoading: false,
        };
      },
    },
  },
  initialState
);
