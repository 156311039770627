import React from 'react'
import { arrayOf, any } from 'prop-types'
import ArticleItem from './BlogItem'

const BlogList = ({ blogs = [] }) => {
  return (
    <div className='articles__list'>
      {blogs.map((article) => (
        <ArticleItem article={article} key={article.id} />
      ))}
    </div>
  )
}

BlogList.propTypes = {
  blogs: arrayOf(any),
}

export default BlogList
