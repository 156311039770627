import React, { PureComponent } from 'react';
import { bool, func, any, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import StaticPage from '../../../../components/StaticPage';
import { createFaqAction, getFaqByIdAction, updateFaqAction } from '../actions';
import {
  errorNotificationAction,
  successNotificationAction,
} from '../../../../containers/Notification/actions';
import {
  ROUTE_TO_BLOG_ADMIN,
  ROUTE_TO_FAQ_ADMIN,
} from '../../../../constants/routes';

class FAQAdminPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      title: '',
      answer: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { pathname } = this.props.history.location;

    const isEdit = pathname.includes('edit');

    if (isEdit) {
      const { id } = this.props.match.params;
      this.props.getFaqById(id);
      this.setState({ isEdit: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.faq !== this.props.faq) {
      this.setState({ ...this.props.faq });
    }
  }

  onSaveHandler = async () => {
    const { title, answer, isEdit, id } = this.state;

    const {
      history,
      createFaq,
      updateFaq,
      successNotification,
      errorNotification,
    } = this.props;

    const newErrors = {};
    if (!title) newErrors.title = 'required';
    if (!answer) newErrors.answer = 'required';

    if (Object.keys(newErrors).length)
      return this.setState({ errors: newErrors });

    const formData = new FormData();
    formData.append('title', title);
    formData.append('answer', answer);

    // eslint-disable-next-line no-unused-expressions
    isEdit ? await updateFaq(id, formData) : await createFaq(formData);

    const message = isEdit
      ? 'FAQ was successfully updated'
      : 'FAQ was successfully created';
    successNotification(message);

    history.push(ROUTE_TO_FAQ_ADMIN);
  };

  render() {
    const { title, answer, isEdit, errors } = this.state;
    const { history } = this.props;

    return (
      <StaticPage pageClassName='blog__page' isAdmin noFooter>
        <div className='site__width'>
          <section className='blog--edit section--content'>
            <form className='form--default'>
              <header className='blog--edit_header clearfix'>
                <div className='blog--edit_header-text'>
                  <h1
                    className='blog--edit_header-title form--title uppercase'
                    style={{ textAlign: 'center' }}>
                    {isEdit ? 'Edit FAQ' : 'Create new FAQ'}
                  </h1>
                </div>
              </header>
              <div
                className='blog--edit_content'
                style={{ justifyContent: 'center' }}>
                <div style={{ width: '50%' }}>
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='title'>
                      Title
                    </label>
                    <input
                      className='form--input'
                      id='title'
                      type='text'
                      name='title'
                      value={title}
                      onChange={(e) =>
                        this.setState({
                          title: e.target.value,
                          errors: { ...errors, title: null },
                        })
                      }
                    />
                    {errors.title ? (
                      <p className='validation-error'>Title is required</p>
                    ) : null}
                  </div>
                  <div className='form--row create-article-title-row'>
                    <label className='form--label' htmlFor='user_type'>
                      Answer
                    </label>
                    <textarea
                      style={{ resize: 'vertical' }}
                      className='form--input'
                      id='answer'
                      name='answer'
                      value={answer}
                      onChange={(e) =>
                        this.setState({
                          answer: e.target.value,
                          errors: { ...errors, answer: null },
                        })
                      }
                    />
                    {errors.answer ? (
                      <p className='validation-error'>Answer is required</p>
                    ) : null}
                  </div>
                </div>
                <div className='form--row form--row_submit'>
                  <button
                    type='button'
                    className='btns btn-i reset_btn'
                    onClick={() => history.push(ROUTE_TO_BLOG_ADMIN)}>
                    CANCEL
                  </button>
                  <button
                    type='button'
                    className='btns btn-i submit_btn'
                    onClick={this.onSaveHandler}>
                    Create new
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </StaticPage>
    );
  }
}

const mapStateToProps = ({ faq }) => ({
  loading: faq.loading,
  errors: faq.errors,
  faq: faq.faq,
});

const mapDispatchToProps = (dispatch) => ({
  createFaq: async (data) => await dispatch(createFaqAction(data)),
  updateFaq: async (id, data) => await dispatch(updateFaqAction(id, data)),
  successNotification: (message) =>
    dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
  getFaqById: async (id) => await dispatch(getFaqByIdAction(id)),
});

FAQAdminPage.propTypes = {
  loading: bool,
  createFaq: func.isRequired,
  updateFaq: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  history: objectOf(any),
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQAdminPage);
