import React from 'react'
import moment from 'moment'

export default class Controls extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMonthSelect: false,
      months: moment.months(),
    }
  }

  renderMonthList = () => {
    const months = []
    moment.months().map((monthName) => {
      return months.push(
        <td
          key={monthName}
          className='calendar-month'
          onClick={() => {
            this.setMonth(monthName)
          }}>
          <span>{monthName}</span>
        </td>
      )
    })

    const rows = []
    let cells = []

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
    })
    rows.push(cells)

    // eslint-disable-next-line react/no-array-index-key
    const monthList = rows.map((row, index) => <tr key={index}>{row}</tr>)

    return (
      <table className='calendar-month'>
        <tbody>{monthList}</tbody>
      </table>
    )
  }

  setMonth = (month) => {
    const monthIndex = this.state.months.indexOf(month)
    const dateObject = moment(this.state.dateObject).set('month', monthIndex)

    this.props.setMonth(dateObject)
    this.setState({ showMonthSelect: false })
    this.props.onMonthSelect(false)
  }

  setShowMonthSelect = () => {
    this.setState({ showMonthSelect: true })
    this.props.onMonthSelect(true)
  }

  render() {
    const { dateObject, changeMonth } = this.props

    const year = dateObject.format('Y')
    const month = dateObject.format('MMMM')
    // const daysInMonth = dateObject.daysInMonth()

    return (
      <div>
        <div className='calendar-controls'>
          <button
            className='btns btn-b booking__calendar--month_prev'
            type='button'
            onClick={() => changeMonth('prev')}>
            <i className='icons i24x24 i-arrow-left_2' />
          </button>

          {!this.state.showMonthSelect ? (
            <span onClick={this.setShowMonthSelect} className='calendar-label'>
              {month} {year}
            </span>
          ) : (
            <span onClick={this.setShowMonthSelect} className='calendar-label'>
              Select a Month
            </span>
          )}
          <button
            className='btns btn-b booking__calendar--month_prev'
            type='button'
            onClick={() => changeMonth('next')}>
            <i className='icons i24x24 i-arrow-right_2' />
          </button>
        </div>
        <div className='calendar-date'>{this.state.showMonthSelect && this.renderMonthList()}</div>
      </div>
    )
  }
}
