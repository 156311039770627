import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { any, bool, func, objectOf, string } from 'prop-types'
import moment from 'moment'
import AppointmentForm from './components/AppointmentForm'
import StaticPage from '../../../components/StaticPage'
import { ROUTE_TO_ALLERGY_TEST_ADMIN, ROUTE_TO_AVAILABILITY_ADMIN } from '../../../constants/routes'
import {
  bookByAdminAction,
  createPatientByAdminAction,
  getResultsAction,
  updatePatientByAdminAction,
} from '../Availability/actions'
import { errorNotificationAction, successNotificationAction } from '../../../containers/Notification/actions'
import { paymentRefundAction } from '../AllergyTest/actions'
import PaymentInfo from './components/PaymentInfo'
import ConfirmationModal from '../../../components/ConfirmationModal'
import AppointmentInfo from './components/AppointmentInfo'
import AppointmentHeader from './components/AppointmentHeader'

const BookingAppointmentAdmin = ({
  bookingData,
  patientData,
  history,
  createPatient,
  updatePatient,
  bookSessionByAdmin,
  successNotification,
  errorNotification,
  getResults,
  transactionData,
  refundLoading,
  paymentRefund,
  type,
}) => {
  useEffect(() => {
    if (history && history.action === 'POP') history.push(ROUTE_TO_ALLERGY_TEST_ADMIN)
  }, [])

  if (!bookingData.id) history.push(ROUTE_TO_AVAILABILITY_ADMIN)

  const isEditPage = type === 'edit'
  if (isEditPage && !patientData) history.push(ROUTE_TO_ALLERGY_TEST_ADMIN)

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)

  const onCancelHandler = async () => {
    try {
      await bookSessionByAdmin(bookingData.id, {
        session: {
          status: 'available',
        },
      })
      history.push(isEditPage ? ROUTE_TO_ALLERGY_TEST_ADMIN : ROUTE_TO_AVAILABILITY_ADMIN)
    } catch (e) {
      console.error(e)
    }
  }

  const onRefundHandler = async () => {
    try {
      await paymentRefund(transactionData.transaction_id)
      successNotification('Refund has been successfully processed')
    } catch (e) {
      errorNotification()
      console.error(e)
    }
    setShowRefundModal(false)
  }

  const { price, age, is_adult, data: date, start_time, end_time, is_video_call: isVideo, id } = bookingData
  const isChild = age === 'child' || !is_adult
  const bookedByClient = transactionData && transactionData.amount

  const onFormSubmit = async ({
    firstName,
    surname,
    email,
    birthDate,
    parentFirstName,
    parentSurname,
    tel,
    GPName,
    GPAddress,
    GPEmail,
    testReason,
    resultAttachment,
    GPAttachment,
    paymentType,
  }) => {
    try {
      // eslint-disable-next-line no-undef
      const formData = new FormData()
      formData.append('patient[first_name]', firstName)
      formData.append('patient[last_name]', surname)
      formData.append('patient[email]', email)
      formData.append('patient[dob]', moment(birthDate).format('YYYY-MM-DD'))
      formData.append('patient[is_adult]', !isChild)
      formData.append('patient[guardian_first_name]', parentFirstName)
      formData.append('patient[guardian_last_name]', parentSurname)
      formData.append('patient[phone]', tel)
      formData.append('patient[session_id]', id)
      formData.append('patient[consultant_name]', GPName)
      formData.append('patient[consultant_addres]', GPAddress)
      formData.append('patient[consultant_email]', GPEmail)
      formData.append('patient[allergens]', testReason)
      formData.append('patient[result]', resultAttachment)
      formData.append('patient[referral_letter]', GPAttachment)
      formData.append('patient[payment_type]', paymentType)

      if (isEditPage) {
        formData.append('patient[id]', patientData.id)
        formData.append('session[id]', patientData.session_id)
      }

      !isEditPage ? await createPatient(formData) : await updatePatient(transactionData.id, formData)

      const message = isEditPage
        ? 'You have successfully updated the appointment.'
        : 'You have successfully created a new appointment.'
      successNotification(message)
      history.push(ROUTE_TO_ALLERGY_TEST_ADMIN)
    } catch (e) {
      errorNotification()
      console.error(e)
    }
  }
  return (
    <StaticPage isAdmin pageClassName='appointments__page'>
      <div className='site__width'>
        <section className='appointment--new section--content'>
          <div className='form--default'>
            {isEditPage ? (
              <AppointmentHeader
                showModalHandler={setShowRefundModal}
                bookedByClient={bookedByClient}
                firstName={patientData.first_name}
                lastName={patientData.last_name}
                status={transactionData.status}
                loading={refundLoading}
              />
            ) : null}
            <div className='appointment--new_content'>
              <AppointmentInfo
                isVideo={isVideo}
                isChild={isChild}
                start_time={start_time}
                end_time={end_time}
                date={date}
                price={price}
              />
              <AppointmentForm
                isChild={isChild}
                isVideo={isVideo}
                onFormSubmit={onFormSubmit}
                patientData={patientData}
                isEditing={isEditPage}
                getResults={getResults}
                bookedByClient={bookedByClient}
              />
              {isEditPage && bookedByClient ? (
                <PaymentInfo date={transactionData.data_payment} time={transactionData.time_payment} />
              ) : (
                <div className='form--column' />
              )}
              <div className='form--row form--row_submit'>
                <button type='button' className='btns btn-i reset_btn' onClick={() => setShowCancelModal(true)}>
                  Cancel
                </button>
                <button form='create-patient-form' type='submit' className='btns btn-i submit_btn'>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </section>
        {showCancelModal ? (
          <ConfirmationModal
            handler={onCancelHandler}
            hideModalHandler={() => setShowCancelModal(false)}
            title='Cancel creating new Appointment?'
            message='All information you entered earlier will be lost.'
            mainButtonContent='Yes'
            cancelButtonContent='No'
          />
        ) : null}
        {showRefundModal ? (
          <ConfirmationModal
            handler={onRefundHandler}
            hideModalHandler={() => setShowRefundModal(false)}
            title='Make Refund ?'
            message='This appointment will be deleted.Are you sure you want to make refund ?'
            mainButtonContent='Refund'
          />
        ) : null}
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ booking: { bookingData, patientData }, appointment: { refundLoading } }, { location }) => {
  const { state, pathname } = location

  if (state) {
    const { patient, ...transaction } = state
    const type = pathname.split('/').pop()

    const dataSession = type === 'create' ? bookingData : patient.session
    const dataPatient = type === 'create' ? patientData : patient
    const transactionData = type !== 'create' ? transaction : null

    return { bookingData: dataSession, patientData: dataPatient, type, transactionData, refundLoading }
  }

  return { bookingData, patientData }
}

const mapDispatchToProps = (dispatch) => ({
  createPatient: (data) => dispatch(createPatientByAdminAction(data)),
  updatePatient: (id, data) => dispatch(updatePatientByAdminAction(id, data)),
  bookSessionByAdmin: (id, data) => dispatch(bookByAdminAction(id, data)),
  paymentRefund: (transaction_id) => dispatch(paymentRefundAction(transaction_id)),
  getResults: (patientId) => dispatch(getResultsAction(patientId)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
})

BookingAppointmentAdmin.propTypes = {
  createPatient: func.isRequired,
  updatePatient: func.isRequired,
  bookSessionByAdmin: func.isRequired,
  paymentRefund: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  getResults: func.isRequired,
  type: string.isRequired,
  transactionData: objectOf(any),
  bookingData: objectOf(any),
  patientData: objectOf(any),
  history: objectOf(any).isRequired,
  refundLoading: bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingAppointmentAdmin)
