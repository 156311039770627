import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { any, arrayOf, bool, func } from 'prop-types'
import { loginSchema } from './validate'

const LoginForm = ({ onSubmit, submitErrors, loading }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={loginSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await onSubmit(values)

          resetForm()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
      }}>
      {({ errors, touched }) => {
        const invalid = submitErrors.length

        return (
          <Form className='signin__form'>
            <div className='signin__form--row'>
              <Field
                className={`signin__form--input ${errors.email && touched.email ? 'error' : ''}`}
                name='email'
                placeholder='Email Address'
              />
              <ErrorMessage name='email' component='div' className='validation-error' />
            </div>
            <div className='signin__form--row'>
              <Field
                className={`signin__form--input ${errors.password && touched.password ? 'error' : ''}`}
                type='password'
                name='password'
                placeholder='Password'
              />
              <ErrorMessage name='password' component='div' className='validation-error' />
            </div>
            {invalid ? <div className='validation-error'>Invalid email or password</div> : null}
            <div className='signin__form--btns'>
              <button className='signin__form--submit btns btn-i' name='submit'>
                {loading ? 'Submitting...' : 'Sign In'}{' '}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

LoginForm.propTypes = {
  onSubmit: func.isRequired,
  submitErrors: arrayOf(any).isRequired,
  loading: bool.isRequired,
}

export default LoginForm
