import typeToReducer from 'type-to-reducer'
import { GET_APPOINTMENTS, GET_EXPORTED_TABLE, PAYMENT_REFUND } from './constants'

export const initialState = {
  appointmentsLoading: false,
  refundLoading: false,
  appointments: [],
  appointmentsPages: 0,
  appointmentsCount: 0,
  errors: [],
}

export default typeToReducer(
  {
    [GET_APPOINTMENTS]: {
      START: (state) => {
        return {
          ...state,
          appointmentsLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          appointments: action.payload.data && action.payload.data.transactions,
          appointmentsPages: action.payload.data && action.payload.data.pages,
          appointmentsCount: action.payload.data && action.payload.data.count,
          appointmentsLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          appointmentsLoading: false,
        }
      },
    },
    [PAYMENT_REFUND]: {
      START: (state) => {
        return {
          ...state,
          refundLoading: true,
        }
      },
      SUCCESS: (state) => {
        return {
          ...state,
          refundLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          refundLoading: false,
        }
      },
    },
  },
  initialState
)
