import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { arrayOf, object, func } from 'prop-types';

const BookingSelect = ({ setHasReferralLetter, options }) => {
  const [select, setSelect] = useState(null);

  // reset form
  useEffect(() => {
    setSelect(null);
    setHasReferralLetter(null);
  }, [options, setHasReferralLetter]);

  const onChange = (hasReferralLetter) => {
    setSelect(hasReferralLetter);
    setHasReferralLetter(hasReferralLetter.value);
  };

  // eslint-disable-next-line react/prop-types
  const renderAddress = ({ value, note }) => {
    if (value === 'child') {
      return note ? (
        <div className='booking__selectAge--note child'>
          <strong>Note:</strong> {note}
        </div>
      ) : null;
    }
    if (value === 'adult') {
      return note ? (
        <div className='booking__selectAge--note adult'>
          <strong>Note:</strong> {note}
        </div>
      ) : null;
    }
    return null;
  };
  return (
    <div className='booking__selectAge'>
      <div className='custom-select'>
        <Select
          value={select}
          options={options}
          onChange={onChange}
          placeholder='Referral letter '
        />
      </div>
      {/* {select && renderAddress(select)} */}
    </div>
  );
};

BookingSelect.propTypes = {
  setAge: func.isRequired,
  options: arrayOf(object).isRequired,
};

export default BookingSelect;
