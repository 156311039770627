import React, { useState } from 'react';
import { shape, string } from 'prop-types';

const FaqItem = ({ item: { title, answer, asHtml } }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div className={`faqs__list--item ${showAnswer ? 'show-answer' : ''}`}>
      <div className='faqs__list--question show-answer'>
        <p>{title}</p>
        <button
          className='btns btn-i btn-show'
          type='button'
          onClick={() => setShowAnswer(!showAnswer)}>
          <i className={`icons i24x24 ${showAnswer ? 'i-minus' : 'i-plus'}`} />
        </button>
      </div>
      <div className='faqs__list--answer'>
        {asHtml ? (
          <p dangerouslySetInnerHTML={{ __html: asHtml }}></p>
        ) : (
          <p>{answer}</p>
        )}
      </div>
    </div>
  );
};

FaqItem.propTypes = {
  item: shape({
    title: string.isRequired,
    answer: string.isRequired,
  }),
};

export default FaqItem;
