import React, { useEffect } from 'react';
import PersonList from '../../PersonList';
import StaticPage from '../../StaticPage';
import { getTeamLandingAction } from './actions';
import { connect } from 'react-redux';
import { DEFAULT_PER_PAGE_TEAM_LANDING } from './constants';

function OurTeam({ team, getTeamLandingAction }) {
  useEffect(() => {
    getTeamLandingAction({ page: 1, per_page: DEFAULT_PER_PAGE_TEAM_LANDING });
  }, []);

  const items = [...team];

  if (team.length) {
    items[1] = team[3];
    items[2] = team[4];
    items[3] = team[1];
    items[4] = team[2];
  }
  return (
    <StaticPage pageClassName='team__page'>
      <div className='site__width'>
        <h1 className='page__title'>Our Team</h1>
        <PersonList items={items ?? []} />
      </div>
    </StaticPage>
  );
}

const mapStateToProps = ({ teamLanding }) => ({
  team: teamLanding.team,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamLandingAction: () => dispatch(getTeamLandingAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);
